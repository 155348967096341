import React from "react"
import {
  Link,
  useLocation
} from "react-router-dom"
import styled from 'styled-components'
import Container from '../Container'

const Wrapper = styled.div`
  background-color: ${props => props.slug === 'nispap' ? '#287092' : '#494D7D'};

  a {
    color: #FFFFFF !important;
  }

  li.selected a,
  li a:hover {
    color: #F88436 !important;
  }

  @media (min-width: 992px) {
    opacity: 1;
    padding: 0.938vw;

    >.content {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      display: inline-flex;
      margin: 0 1.302vw;
    }

    a {
      color: #FFFFFF;
      font-size: 0.938vw;
      font-weight: bold;
      line-height: 1.250vw;
      text-decoration: none;
      transition: .3s ease;
      text-transform: uppercase;
    }
  }

  @media (max-width: 992px) {
    margin-top: 10px;  
    padding: 5px 0;

    li {
      border: none !important;
    }

    a {
      
      font-size: 20px;
    }
  }

  @media (min-width: 1920px) {
    ul>li {
      margin: 0 20px;
    }

    li a {
      font-size: 18px;
      line-height: 24px;
    }
  }
`

export default function SubNavigation( props ) {
  const location = useLocation();
  const splitPath = location.pathname.split('/')[1]
  if (
    ![
      'spap',
      'socios',
      'ferramentas-uteis',
      'nispap'
    ].includes(splitPath)
  ) return false

  // #TODO Move to json file
  const ListNavItems = [
    {
      'path' : 'spap',
      'itens' : [
        {
          'title' : 'Quem somos?',
          'link' : '/spap/quem-somos'
        },
        {
          'title' : 'O que é Anatomia Patológica?',
          'link' : '/spap/anatomia-patologica'
        },
        {
          'title' : 'Órgãos Sociais',
          'link' : '/spap/orgaos-sociais'
        },
        {
          'title' : 'História',
          'link' : '/spap/historia'
        },
        {
          'title' : 'Bolsas e Prémios',
          'link' : '/spap/bolsas-e-premios'
        },
        {
          'title' : 'Estatutos e Documentos',
          'link' : '/spap/estatutos-e-documentos'
        },
        {
          'title' : 'Contactos',
          'link' : '/spap/contactos'
        }
      ]
    },
    {
      'path' : 'socios',
      'itens' : [
        {
          'title' : 'Área de Sócio',
          'link' : '/socios/area-de-socio'
        },
        {
          'title' : 'Lista de Sócios',
          'link' : '/socios/lista-de-socios'
        },
        {
          'title' : 'Conheça as Vantagens',
          'link' : '/socios/conheca-as-vantagens'
        },
        {
          'title' : 'Destaques e notícias',
          'link' : '/socios/destaques'
        },
        {
          'title' : 'Clubes de Estudo',
          'link' : '/socios/clubes-de-estudo'
        },
        {
          'title' : 'Perguntas Frequentes',
          'link' : '/socios/perguntas-frequentes'
        }
      ]
    },
    {
      'path' : 'ferramentas-uteis',
      'itens' : [
        {
          'title' : 'Pedir uma 2ª Opinião',
          'link' : '/ferramentas-uteis/pedir-uma-segunda-opiniao'
        },
        {
          'title' : 'Pesquisar um Anticorpo',
          'link' : '/ferramentas-uteis/pesquisar-um-anticorpo'
        },
        {
          'title' : 'Guidelines',
          'link' : '/ferramentas-uteis/guidelines'
        },
        /*
        {
          'title' : 'Caso do Mês',
          'link' : '/ferramentas-uteis/caso-do-mes'
        },
        */
        {
          'title' : 'Formação',
          'link' : '/ferramentas-uteis/formacao'
        },
        {
          'title' : 'Publicações',
          'link' : '/ferramentas-uteis/publicacoes'
        },
        {
          'title' : 'Revista',
          'link' : '/ferramentas-uteis/revista'
        },
        {
          'title' : 'Parcerias',
          'link' : '/ferramentas-uteis/parcerias'
        }
      ]
    },
    {
      'path' : 'nispap',
      'itens' : [
        {
          'title' : 'NISPAP',
          'link' : '/nispap'
        },
        {
          'title' : 'Sobre Nós',
          'link' : '/nispap/sobre-nos/quem-somos'
        },
        {
          'title' : 'Novidades',
          'link' : '/nispap/novidades/eventos-nispap'
        },
        {
          'title' : 'Utilitários',
          'link' : '/nispap/utilitarios/estagios'
        },
        {
          'title' : 'Contactos',
          'link' : '/nispap/contactos'
        }
      ]
    }
  ]

  const NavItems = ListNavItems.find(obj => {
    return obj.path === splitPath;
  });

  let simplePath = location.pathname

  return(
    <Wrapper slug={splitPath}>
      <Container className="content">
        <ul>
          {
            NavItems.itens.map((item, index) => {
              if (item.link === '/spap/anatomia-patologica' && (simplePath === '/spap/glossario' || simplePath === '/spap/conheca-um-patologista')) simplePath = item.link
              if (item.link === '/spap/historia' && (simplePath === '/spap/presidentes' || simplePath === '/spap/congressos')) simplePath = item.link
              if (item.link === '/spap/bolsas-e-premios' && simplePath.includes('/spap/noticia')) simplePath = item.link
              if (item.link === '/spap/bolsas-e-premios' && simplePath.includes('/spap/bolsas-e-premios/')) simplePath = item.link
              if (item.link === '/socios/area-de-socio' && simplePath.includes('/socios/mensagens')) simplePath = item.link
              if (item.link === '/socios/area-de-socio' && simplePath.includes('/socios/dados-de-perfil')) simplePath = item.link
              if (item.link === '/socios/area-de-socio' && simplePath.includes('/socios/documentos')) simplePath = item.link
              if (item.link === '/socios/area-de-socio' && simplePath.includes('/socios/quotas')) simplePath = item.link
              if (item.link === '/socios/clubes-de-estudo' && simplePath.includes('/socios/clubes-de-estudo')) simplePath = item.link
              if (item.link === '/socios/destaques' && simplePath.includes('/socios/destaques')) simplePath = item.link
              if (item.link === '/ferramentas-uteis/caso-do-mes' && simplePath.includes('/ferramentas-uteis/caso-do-mes')) simplePath = item.link
              if (item.link === '/ferramentas-uteis/formacao' && simplePath.includes('/ferramentas-uteis/formacao')) simplePath = item.link
              /* NISPAP */
              if (item.link === '/nispap/sobre-nos/quem-somos' && simplePath.includes('/nispap/sobre-nos')) simplePath = item.link
              if (item.link === '/nispap/novidades/eventos-nispap' && simplePath.includes('/nispap/novidades')) simplePath = item.link
              if (item.link === '/nispap/utilitarios/estagios' && simplePath.includes('/nispap/utilitarios')) simplePath = item.link

              return (
                <li key={'subNavigation' + index} className={'item-' + (index + 1) + ((item.link === simplePath) ? ' selected' : '')}>
                  <Link to={item.link}>
                    {item.title}
                  </Link>
                </li>
              )
            })
          }
        </ul>
      </Container>
    </Wrapper>
  )
}
