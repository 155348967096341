import styled from "styled-components";

import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import PageText from "../../../Components/PageText";
import ContactForm from "../../../Components/ContactForm";
import Newsletter from "../../../Components/Newsletter";
import { Helmet } from "react-helmet";
const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    padding-bottom: 4vw;
  }

  .image {
    margin: 0 auto auto;
    max-width: 32.292vw;
  }

  img {
    width: 100%;
  }

  p {
    text-align: left;
    font-size: 1.042vw;
    line-height: inherit;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0;
  }

  a {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.563vw;
    letter-spacing: 0px;
    color: #f88436;
    opacity: 1;
    place-self: flex-start;
    transition: 0.3s ease-out;
  }

  a:hover {
    text-decoration: none;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    p,
    a {
      font-size: 14px;
      line-height: 18px;
    }
  }

  @media (min-width: 1920px) {
    .image {
      width: 330px;
      max-width: 330px;
    }
  }
`;

export default function Contactos(props) {
  const ContactItens = [
    {
      name: "M. Augusta Cipriano - Presidente",
      email: ["direcao@spap.pt"],
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <title>
          Contactos | Sociedade Portuguesa de Anatomia Patológica - SPAP
        </title>
        <meta
          name="description"
          content="A Sociedade Portuguesa de Anatomia Patológica está sempre ao seu dispor. Entre em contacto e coloque-nos as suas dúvidas."
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container className="content">
        <PageTitle
          title="Contactos"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "SPAP",
              link: "/spap",
            },
            {
              text: "Contactos",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/img-contactos@2x.png")}
          imageMobile={require("../../../Assets/Images/img-contactos.png")}
          imageAlt="Contactos"
        />

        <FlexInRow gap="3.646vw">
          <FlexInColumn gap="1.563vw" mobile="column">
            <FlexInRow gap="50px">
              <FlexInRow gap="0">
                <PageText title="A Sociedade Portuguesa de Anatomia Patológica está sempre ao seu dispor" />
                <PageText title="Entre em contacto e coloque-nos as suas dúvidas" />
              </FlexInRow>
              {ContactItens.length > 0 &&
                ContactItens.map((item, index) => {
                  return (
                    <FlexInRow gap="0.313vw">
                      <p>{item.name}</p>
                      {item.email.length > 0 &&
                        item.email.map((email) => {
                          return <a href={"mailto:" + email}>{email}</a>;
                        })}
                    </FlexInRow>
                  );
                })}

              <FlexInColumn gap="5.208vw" mobile="column">
                <FlexInRow gap="0.313vw" width={"330px"}>
                  <p>Catarina Eloy - Vice-Presidente</p>
                  <a href="mailto:catarinaeloy@hotmail.com">
                    catarinaeloy@hotmail.com
                  </a>
                </FlexInRow>
                <FlexInRow gap="0.313vw">
                  <p>Carla Bartosch - Vogal -Tesoureira</p>
                  <a href="mailto:carla.bartosch@ipoporto.min-saude.pt">
                    carla.bartosch@ipoporto.min-saude.pt
                  </a>
                  <a href="mailto:socios@spap.pt">socios@spap.pt</a>
                </FlexInRow>
              </FlexInColumn>
              <FlexInColumn gap="5.208vw" mobile="column">
                <FlexInRow gap="0.313vw" width={"330px"}>
                  <p>Joaninha Costa Rosa - Vice-Presidente</p>
                  <a href="mailto:jrosa@ipolisboa.min-saude.pt">
                    jrosa@ipolisboa.min-saude.pt
                  </a>
                </FlexInRow>
                <FlexInRow gap="0.313vw">
                  <p>Daniel Pinto - Vogal</p>
                  <a href="mailto:danielgomespinto@gmail.com">
                    danielgomespinto@gmail.com
                  </a>
                </FlexInRow>
              </FlexInColumn>
              <FlexInColumn gap="5.208vw" mobile="column">
                <FlexInRow gap="0.313vw" width={"330px"}>
                  <p>Raquel Ilgenfritz - Secretária Geral</p>
                  <a href="mailto:raquel.fritz@gmail.com">
                    raquel.fritz@gmail.com
                  </a>
                  <a href="mailto:site@spap.pt">site@spap.pt</a>
                </FlexInRow>
                <FlexInRow gap="0.313vw">
                  <p>Rui Caetano Oliveira - Vogal</p>
                  <a href="mailto:ruipedrocoliveira@hotmail.com">
                    ruipedrocoliveira@hotmail.com
                  </a>
                </FlexInRow>
              </FlexInColumn>
            </FlexInRow>
            <picture className="image">
              <source
                srcSet={require("../../../Assets/Images/img-contacto@2x.png")}
                media="(min-width: 990px)"
              />
              <img
                src={require("../../../Assets/Images/img-contacto.png")}
                alt="Faça Contacto"
              />
            </picture>
          </FlexInColumn>
        </FlexInRow>
      </Container>

      <ContactForm />

      <Newsletter />
    </Wrapper>
  );
}
