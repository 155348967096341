import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Input,
  Space,
  notification,
  Select,
  Row,
  Col,
  Button,
  Radio,
  message,
  Statistic,
} from "antd";
import { CopyOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";

import Container from "../../../Components/Container";
import PageTitle from "../../../Components/PageTitle";
import SidebarMenu from "../../../Components/SidebarMenu";
import RecommendedLinks from "../../../Components/RecommendedLinks";

import PageText from "../../../Components/PageText";

import MBImage from "../../../Assets/Images/Pagamentos/MB.svg";
import MBWayImage from "../../../Assets/Images/Pagamentos/MB-Way.svg";
import MultibancoImage from "../../../Assets/Images/Pagamentos/Multibanco.svg";
import BankTransferImage from "../../../Assets/Images/Pagamentos/Bank-transfer.svg";
import IconWarning from "../../../Assets/Images/Pagamentos/Icon-warning.svg";

import axios from "axios";
import Payment from "../../../Components/Payment";
import FlexInRow from "../../../Components/FlexInRow";
const { Countdown } = Statistic;

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #000000;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  width: 100%;
  margin-top: 7.813vw;

  img {
    width: 100%;
  }

  .ant-space-item {
    color: #000;
  }

  .page-text {
    margin-top: -50px;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .page-text {
      margin-top: 20px;
    }
  }

  h5 {
    text-align: left;
  }
`;

const LoginMessage = styled.span`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
`;
const Card = styled.div`
  background-color: #e8e9f3;
  border-radius: 16px;
  margin-bottom: 50px;
  padding: 30px;
  width: 100%;

  h3 {
    text-align: left;
    font: normal normal bold 22px/32px Roboto;
    letter-spacing: 0px;
    color: #494d7d;
  }

  p {
    text-align: left;
    font: normal normal normal 14px/17px Roboto;
    letter-spacing: 0px;
    color: #494d7d;
    margin: 0;
  }

  p.info {
    text-align: left;
    font: normal normal normal 12px/14px Roboto;
    letter-spacing: 0px;
    color: #494d7d;
  }

  .paymentValue {
    text-align: center;
    font: normal normal bold 60px/55px Roboto;
    letter-spacing: 0px;
    color: #2ecc71;
    margin: 15px 0;
  }

  .paymentSubmit {
    background: #f88436 0% 0% no-repeat padding-box;
    border-radius: 10px;
    text-align: left;
    font: normal normal bold 18px/22px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
    border: none;
    height: 52px;
    padding: 0 25px;

    :hover {
      color: #efefef;
    }
  }

  .cancelPayment {
    background: transparent !important;
    border-radius: 10px;
    text-align: left;
    font: normal normal bold 18px/22px Roboto;
    border: 1px solid #f88436 !important;
    letter-spacing: 0px;
    color: #f88436 !important;
    height: 52px;
    padding: 0 25px;
  }

  &.white {
    background-color: #ffffff;
    border-radius: 8px;
  }

  &.flex {
    display: flex;
    flex-direction: column;
    gap: 14px;

    p {
      margin: auto;
      text-align: center;
    }
  }

  &.boxShadow {
    box-shadow: 0px 3px 10px #00000029;
  }

  &.radio {
    margin-bottom: 20px;
    padding: 12px 16px;
  }

  &.paymentAlert {
    padding-top: 45px;
  }

  @media (max-width: 992px) {
    .paymentSubmit {
      margin-top: 30px;
    }
  }
`;

const PaymentAlert = styled.div`
  background: #fcc29b 0% 0% no-repeat padding-box;
  border-radius: 16px;
  padding: 12px 18px;
  margin-bottom: -30px;
  z-index: 9;
  width: 100%;

  * {
    text-align: left;
    font: italic normal bold 17px/21px Fira Sans;
    letter-spacing: 0px;
    color: #0c1151;
    margin: auto;
  }
`;

const PaymentBar = styled.div`
  border: 1px solid #2ecc71;
  margin: auto;
  width: 50%;
`;

export default function Pagamentos(props) {
  const [api, contextHolder] = notification.useNotification();

  const [paymentType, setPaymentType] = useState(null);
  const onChangeType = (e) => {
    setPaymentType(e.target.value);
  };

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  const session_storage = JSON.parse(localStorage.getItem("session_data"));

  const [allItems, setAllItems] = useState([]);
  const [listItems, setListItems] = useState([]);

  const [loadings, setLoadings] = useState([]);
  const [loadingsCancel, setLoadingsCancel] = useState([]);
  const [nifInstituicao, setNifInstituicao] = useState("");
  const [morada, setMorada] = useState("");
  const [valueTel, setValueTel] = useState("");
  const [optionalPayment, setOptionalPayment] = useState("");
  const [userType, setUserType] = useState("");
  const [myUser, setMyUser] = useState({});
  const [userTypeText, setUserTypeText] = useState("Sócio Titular");
  const [itemPayment, setItemPayment] = useState([]);
  const [valorAPagar, setValorAPagar] = useState(Number(0));
  const [currentPlan, setCurrentPlan] = useState({});
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [pendingPayment, setPendingPayment] = useState(false);
  const [pendentAlert, setPendentAlert] = useState({});
  const [anosAnterioresPendentes, setAnosAnterioresPendentes] = useState([]);
  const [descricaoPagamentoAMostrar, setDescricaoPagamentoAMostrar] = useState(
    []
  );
  const [descricaoPagamento, setDescricaoPagamento] = useState([]);
  const [pendentPurchase, setPendentPurchase] = useState(0);
  const [countdown, setCountdown] = useState();
  const [pendentESP, setPendentESP] = useState(false);
  const [quotasPorPagar, setQuotasPorPagar] = useState(false);
  const SidebarMenuLinks = [
    {
      title: "Área pessoal",
      link: "/socios/area-de-socio",
    },
    {
      title: "Dados de perfil",
      link: "/socios/dados-de-perfil/",
    },
    {
      title: "Quotas",
      link: "/socios/quotas",
    },
    {
      title: "Pagamentos",
      link: "/socios/pagamentos",
    },
    {
      title: "Meus Eventos",
      link: "/socios/meus-eventos",
    },
    {
      title: "Mensagens",
      link: "/socios/mensagens",
    },
  ];

  const [purchases, setPurchases] = useState(null);
  const [pendent, setPendent] = useState(null);
  const [lastPayment, setLastPayment] = useState([]);

  useEffect(() => {
    fetchPurchases();
    fetchData();
  }, []);

  useEffect(() => {
    if (Object.keys(pendentAlert).length) {
      var pendingDate = new Date(pendentAlert.updated_at);
      var expiryDate = new Date(pendentAlert.updated_at);
      if (pendentAlert.payment_type == "Multibanco") {
        expiryDate.setDate(expiryDate.getDate() + 3);
      } else {
        expiryDate.setMinutes(expiryDate.getMinutes() + 5);
      }
      // Add five days to current date
      if (new Date() >= expiryDate) {
        fetchPurchases();
        fetchData();
      } else {
        setCountdown(expiryDate.getTime());
      }
    }
  }, [pendentAlert]);

  const fetchData = () => {
    axios
      .get(
        `https://api.spap.pt/api/get-user/` +
          session_storage.all_data.user_session.id,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        setMyUser(data.user);
        setValueTel(data.user.telefone);
        setNifInstituicao(data.user.nifInstituicao);
        setMorada(data.user.morada);
      });
  };

  useEffect(() => {
    if (Object?.keys(myUser)?.length) {
      let currentPlan = handleCurrentPlan(myUser.plans);
      let anosPendentes = handleValorAnosAnteriores(myUser.quotas);
      handlePendingPayment(myUser.quotas);
      verifyUserType(myUser, anosPendentes, currentPlan);
    }
  }, [myUser]);

  const fetchPurchases = () => {
    axios
      .get("https://api.spap.pt/api/getPurchases", {
        params: { email: session_storage.all_data.user_session.email },
      })
      .then((response) => {
        let dataPendent = response.data.filter((purchase) => {
          return (
            purchase.payment_id != "" &&
            purchase.payment_id != null &&
            purchase.status == "nao pago"
          );
        });

        if (!dataPendent.length) {
          setPendentAlert({});
          return;
        }

        if (Array.isArray(dataPendent) && dataPendent.length) {
          fetchPaymentStatus(dataPendent[0].payment_id, dataPendent);
        }
      });
  };

  const fetchPaymentStatus = async (requestId, dataPendent) => {
    const url = `https://ifthenpay.com/api/spg/payment/mbway/status?mbWayKey=YUH-195600&requestId=${requestId}`;
    const response = await fetch(url);
    const data = await response.json();
    let status = data.Status; // Ou algum status específico da resposta
    let message = data.Message;
    if (message == "Success") {
      axios
        .post("https://api.spap.pt/api/success-payment", {
          payment_id: requestId,
        })
        .then((response) => {
          notification.success({
            message: `Sucesso`,
            description: "Pagamento efetuado com sucesso",
          });
          setPendentAlert({});
          setPendingPayment(false);
          fetchPurchases();
          fetchData();
        })
        .catch((error) => {
          console.error("Erro na requisição:", error);
        });
    } else if (status == "020" || status == "101" || status == "122") {
      axios
        .post("https://api.spap.pt/api/remove-payment", {
          payment_id: requestId,
        })
        .then((response) => {
          notification.warning({
            message: `Sucesso`,
            description: "O seu pagamento expirou. Por favor, tente de novo.",
          });
          fetchData();
        })
        .catch((error) => {
          console.error("Erro na requisição:", error);
        });
    } else {
      setPendentAlert(dataPendent[0]);
    }
  };

  const handleCurrentPlan = (plans) => {
    if (!plans) {
      return;
    }
    let currentPlan = plans.filter((plan) => {
      var year = plan.date_start.substring(
        plan.date_start.lastIndexOf("/") + 1
      );
      if (new Date().getFullYear() == year) {
        return plan;
      }
    });
    if (!currentPlan.length) {
      return;
    }
    setCurrentPlan(currentPlan[0]);
    return currentPlan;
  };

  const handlePendingPayment = (quotas) => {
    if (!quotas) {
      return;
    }
    let quotasPendentes = [];
    quotas.map((quota) => {
      if (
        quota.status === "nao pago" &&
        quota.ano == new Date().getFullYear()
      ) {
        quotasPendentes.push(quota);
      }
    });
    if (
      !quotasPendentes.length ||
      (quotasPendentes.length == 1 && quotasPendentes[0].tipo == "ESP")
    ) {
      setPendingPayment(false);
    } else {
      setPendingPayment(true);
    }
  };

  const handleValorAnosAnteriores = (quotas) => {
    let anosPendentes = [];
    quotas.forEach((quota) => {
      if (
        (quota.status == "nao pago" || !quota.status) &&
        !anosPendentes.includes(quota.ano) &&
        quota.ano != new Date().getFullYear()
      ) {
        anosPendentes.push(quota.ano);
      }
    });
    if (!anosPendentes.length) {
      return;
    }
    setAnosAnterioresPendentes(anosPendentes);
    return anosPendentes;
  };

  const checkPaymentStatus = async () => {
    try {
      fetchPurchases();
    } catch (error) {
      console.error("Erro ao verificar o status do pagamento", error);
    }
  };

  const handleChangeTel = (e) => {
    const { value } = e.target;
    const reg = /^[0-9]*$/; // Regex para aceitar apenas números
    if ((!Number.isNaN(value) && reg.test(value)) || value === "") {
      setValueTel(value);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      message.success("Copiado para a área de transferência!");
    });
  };

  function handleClosePaymentSteps() {
    setPaymentOptions(false);
    setItemPayment([]);
    fetchPurchases();
  }
  const [paymentOptions, setPaymentOptions] = useState(false);

  const verifyUserType = async (user, anosPendentes, plan) => {
    let grau = user.grau;
    let tipoSocio = user.tipo_socio;
    let selectOptions = [];

    setOptionalPayment("");

    let tiposQuotaPorPagar = [];

    if (grau == "Interno") {
      selectOptions.push({ value: "SPAP", label: "SPAP" });
    } else {
      user.quotas.forEach((quota) => {
        if (
          quota.status == "nao pago" &&
          quota.ano == new Date().getFullYear()
        ) {
          tiposQuotaPorPagar.push(quota.tipo);
        }
      });
      if (
        tiposQuotaPorPagar.includes("SPAP") &&
        tiposQuotaPorPagar.includes("ESP")
      ) {
        selectOptions.push({ value: "SPAP+ESP", label: "SPAP + ESP" });
      }
      if (tiposQuotaPorPagar.includes("SPAP")) {
        selectOptions.push({ value: "SPAP", label: "SPAP" });
      }
    }

    if (tipoSocio == "Honorário" && tiposQuotaPorPagar.length > 0) {
      setOptionalPayment("(pagamento opcional para sócios honorários)");
      setPendentESP(true);
      return;
    }

    if (tiposQuotaPorPagar.length == 1 && tiposQuotaPorPagar[0] == "ESP") {
      setPendentESP(true);
      return;
    }

    setSelectOptions(selectOptions);
    setSelectedOption(selectOptions[0].value);
    handleAnosValorAPagar(
      selectOptions[0].value,
      anosPendentes,
      myUser.quotas,
      plan[0]
    );
  };

  const handleAnosValorAPagar = (value, anosPendentes, quotas, plan) => {
    let quotaAtual = quotas.filter((quota) => {
      return (
        quota.ano == new Date().getFullYear() && quota.status == "nao pago"
      );
    });
    var novoValor = 0;

    let quotasPorPagar = [];

    quotaAtual.forEach((quota) => {
      if (value == "SPAP+ESP") {
        if (quota.tipo == "SPAP") {
          let valor = Number(quota.valor)
            ? Number(quota.valor)
            : Number(plan.payment_value_spap);
          novoValor += valor;
          let arrayQuota = {
            tipo: "SPAP",
            valor: valor,
            ano: new Date().getFullYear(),
          };
          quotasPorPagar.push(arrayQuota);
        }
        if (quota.tipo == "ESP") {
          let valor = Number(quota.valor)
            ? Number(quota.valor)
            : Number(plan.payment_value_spap_esp) -
              Number(plan.payment_value_spap);
          novoValor += valor;
          let arrayQuota = {
            tipo: "ESP",
            valor: valor,
            ano: new Date().getFullYear(),
          };
          quotasPorPagar.push(arrayQuota);
        }
      } else if (value == "SPAP") {
        if (quota.tipo == "SPAP") {
          let valor = Number(quota.valor)
            ? Number(quota.valor)
            : Number(plan.payment_value_spap);
          novoValor += valor;
          let arrayQuota = {
            tipo: "SPAP",
            valor: valor,
            ano: new Date().getFullYear(),
          };
          quotasPorPagar.push(arrayQuota);
        }
      }
    });

    setSelectedOption(value);

    if (anosPendentes?.length > 0) {
      anosPendentes.map((ano) => {
        let arrayQuota = { tipo: "", valor: 30, ano: ano };
        quotasPorPagar.push(arrayQuota);
      });
      novoValor = novoValor + Number(anosPendentes.length) * 30;
      if (novoValor > 120) {
        novoValor = 120;
      }
      setDescricaoPagamentoAMostrar(
        "Valor inclui quota(s) pendente(s) de " + anosPendentes.join(", ")
      );
      setDescricaoPagamento(
        "Quota " + value + " e ano(s) pendente(s) " + anosPendentes.join(", ")
      );
    } else {
      setDescricaoPagamento("Quota " + value);
    }
    setValorAPagar(novoValor);
    setQuotasPorPagar(quotasPorPagar);
  };

  const cancelPendent = async () => {
    enterLoadingCancel(1);
    const url = `https://ifthenpay.com/api/spg/payment/mbway/status?mbWayKey=YUH-195600&requestId=${pendentAlert.payment_id}`;
    const response = await fetch(url);
    const data = await response.json();
    let status = data.Status; // Ou algum status específico da resposta
    let message = data.Message;
    if (message == "Success") {
      axios
        .post("https://api.spap.pt/api/success-payment", {
          payment_id: pendentAlert.payment_id,
        })
        .then((response) => {
          notification.success({
            message: `Sucesso`,
            description: "Pagamento efetuado com sucesso",
          });
          setPendentAlert({});
          setPendingPayment(false);
          fetchPurchases();
          fetchData();
        })
        .catch((error) => {
          console.error("Erro na requisição:", error);
        });
    } else {
      axios
        .post("https://api.spap.pt/api/remove-payment", {
          payment_id: pendentAlert.payment_id,
        })
        .then((response) => {
          notification.success({
            message: `Sucesso`,
            description: "O seu pagamento foi cancelado com sucesso.",
          });
          fetchData();
          setPendentAlert({});
        })
        .catch((error) => {
          console.error("Erro na requisição:", error);
        });
    }
  };

  const valueBankTransferAccount = "PT 50 0036 0058 99100033552 62";

  const onConfirmOrCancelPayment = (label) => async () => {
    enterLoading(1);
    const url = `https://ifthenpay.com/api/spg/payment/mbway/status?mbWayKey=YUH-195600&requestId=${pendentAlert.payment_id}`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      let status = data.Status; // Ou algum status específico da resposta
      let message = data.Message;
      if (message == "Success") {
        axios
          .post("https://api.spap.pt/api/success-payment", {
            payment_id: pendentAlert.payment_id,
          })
          .then((response) => {
            notification.success({
              message: `Sucesso`,
              description: "Pagamento efetuado com sucesso",
            });
            setPendentAlert({});
            setPendingPayment(false);
            fetchPurchases();
            fetchData();
          })
          .catch((error) => {
            console.error("Erro na requisição:", error);
          });
      } else {
        if (label == "confirm") {
          notification.error({
            message: `Erro`,
            description: "O pagamento ainda não foi efetuado.",
          });
        } else if ((label = "expired")) {
          axios
            .post("https://api.spap.pt/api/remove-payment", {
              payment_id: pendentAlert.payment_id,
            })
            .then((response) => {
              notification.warning({
                message: `Warning`,
                description:
                  "O seu pagamento expirou. Por favor, tente de novo.",
              });
              setPendentAlert({});
              fetchPurchases();
              fetchData();
            })
            .catch((error) => {
              console.error("Erro na requisição:", error);
            });
        }
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
      return null; // ou outro valor representando falha
    }
  };

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 3000);
  };

  const enterLoadingCancel = (index) => {
    setLoadingsCancel((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadingsCancel((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 3000);
  };

  return (
    <>
      <Wrapper key={"pagamentos"}>
        <Container>
          <Row gutter={[24, 24]}>
            <Col xl={19} xs={24}>
              <Payment
                user={session_storage.all_data.user_session}
                show={paymentOptions}
                close={handleClosePaymentSteps}
                price={itemPayment.price}
                paymentType={paymentType}
                phone={valueTel ? valueTel : ""}
                tipo={itemPayment.tipo}
                planId={itemPayment.planId}
                planDescription={itemPayment.planDescription}
                listQuotas={quotasPorPagar}
                planEntity={"12514"}
                planReference={"502"}
                expiryDate={paymentType == "MBWay" ? "5 minutos" : "72 horas"}
              />
              <PageTitle
                title="Pagamentos"
                path={[
                  {
                    text: "Homepage",
                    link: "/",
                  },
                  {
                    text: "Sócios",
                    link: "/socios",
                  },
                  {
                    text: "Área de sócio",
                    link: "/socios/area-de-socio",
                  },
                  {
                    text: "Pagamentos",
                  },
                ]}
                imageDesktop={require("../../../Assets/Images/Pagamentos/pagamentos@2x.png")}
                imageMobile={require("../../../Assets/Images/Pagamentos/pagamentos.png")}
                imageAlt="Sócios - Pagamentos"
                border={true}
              />

              <FlexInRow gap="0">
                <LoginMessage style={{ margin: "10px 0" }}>
                  Verifique aqui todas as Quotas e Eventos que tem em pagamento
                </LoginMessage>
              </FlexInRow>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  {/* <Row className="page-text" style={{ marginBottom: "20px" }}>
                    <PageText title="Verifique aqui todas as Quotas e Eventos que tem em pagamento" />
                  </Row> */}
                  {Object.keys(pendentAlert).length ? (
                    <>
                      <Row>
                        <PaymentAlert>
                          <Space size={18}>
                            <img
                              src={IconWarning}
                              alt="Pagamento pendente"
                              style={{ width: "26px" }}
                            />
                            <p>Pagamento pendente</p>
                          </Space>
                        </PaymentAlert>
                        <Card className="paymentAlert">
                          <Row>
                            <h3>{/* {item.title}  */}</h3>
                          </Row>
                          <Row>
                            <Col span={14}>
                              <p>
                                O seu perfil está assinalado como:{" "}
                                <b>{myUser.grau}</b>
                              </p>
                              <p style={{ marginTop: "5px" }}>
                                Para solicitar alteração envie um email para{" "}
                                <u>
                                  <a href="mailto:site@spap.pt?subject=Solicitar alteração Tipo de Sócio">
                                    site@spap.pt
                                  </a>
                                </u>
                              </p>
                            </Col>
                          </Row>
                          <Row gutter={32} style={{ marginTop: "20px" }}>
                            <Col xs={24} sm={24} md={16} lg={18} xl={18}>
                              <Row gutter={32} style={{ marginBottom: "20px" }}>
                                <Col>
                                  <b>Tens um pedido de pagamento em aberto</b>
                                </Col>
                              </Row>
                              <Row gutter={32}>
                                <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                                  <Row>
                                    {pendentAlert.payment_type === "MBWay" && (
                                      <img
                                        src={MBWayImage}
                                        alt="Pagamento com MBWay"
                                      />
                                    )}
                                    {pendentAlert.payment_type ===
                                      "Multibanco" && (
                                      <img
                                        src={MultibancoImage}
                                        alt="Pagamento com Multibanco"
                                      />
                                    )}
                                  </Row>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={16} xl={16}>
                                  {pendentAlert.payment_type ===
                                  "Multibanco" ? (
                                    <>
                                      <Countdown
                                        title="Tempo que falta"
                                        value={countdown}
                                        format="HH:mm:ss"
                                        style={{ textAlign: "right" }}
                                        onFinish={onConfirmOrCancelPayment(
                                          "expired"
                                        )}
                                      />
                                      <Card className="white boxShadow">
                                        <Row>
                                          <p>
                                            <b>
                                              Dados fornecidos para pagamento:
                                            </b>
                                          </p>
                                        </Row>
                                        <Row
                                          style={{
                                            padding: "30px",
                                            backgroundColor: "#F3F6F9",
                                            marginTop: "20px",
                                          }}
                                        >
                                          <Col span={4}>
                                            <img
                                              src={MBImage}
                                              alt="Pagamento via MB"
                                            />
                                          </Col>
                                          <Col
                                            span={20}
                                            style={{ paddingLeft: "20px" }}
                                          >
                                            <p style={{ marginBottom: "8px" }}>
                                              <b>Entidade</b>{" "}
                                              {pendentAlert.entity}
                                            </p>
                                            <p style={{ marginBottom: "8px" }}>
                                              <b>Referência</b>{" "}
                                              {pendentAlert.reference}
                                            </p>
                                            <p>
                                              <b>Montante</b>{" "}
                                              {pendentAlert.payment_value} €
                                            </p>
                                          </Col>
                                        </Row>
                                      </Card>
                                    </>
                                  ) : pendentAlert.payment_type == "MBWay" ? (
                                    <>
                                      <Countdown
                                        title="Tempo que falta"
                                        value={countdown}
                                        format="HH:mm:ss"
                                        style={{ textAlign: "right" }}
                                        onFinish={onConfirmOrCancelPayment(
                                          "expired"
                                        )}
                                      />
                                      <Card className="white boxShadow">
                                        <Row>
                                          <p>
                                            <b>Dados para pagamento:</b>
                                          </p>
                                        </Row>
                                        <Row
                                          style={{
                                            padding: "30px",
                                            backgroundColor: "#F3F6F9",
                                            marginTop: "20px",
                                          }}
                                        >
                                          <Col span={4}>
                                            <img
                                              src={MBImage}
                                              alt="Pagamento via MB"
                                            />
                                          </Col>
                                          <Col
                                            span={20}
                                            style={{ paddingLeft: "20px" }}
                                          >
                                            <p style={{ marginBottom: "8px" }}>
                                              <b>Número de telemóvel</b>
                                            </p>
                                            <Input
                                              placeholder="Número de telemóvel"
                                              value={valueTel}
                                              disabled
                                              onChange={handleChangeTel}
                                              style={{
                                                marginBottom: "8px",
                                                padding: "10px 8px",
                                                border: "2px solid #C2C4D4",
                                                background: "#D9E1E700",
                                              }}
                                            />
                                            <p>
                                              <b>Quantia</b>{" "}
                                              {pendentAlert.payment_value} €
                                            </p>
                                          </Col>
                                        </Row>
                                      </Card>
                                    </>
                                  ) : paymentType === "debit" ? (
                                    <Card className="white boxShadow">
                                      <Row>
                                        <Space size={18}>
                                          <img
                                            src={BankTransferImage}
                                            alt="Pagamento com transferencia bancária"
                                            style={{
                                              width: "26px",
                                              height: "26px",
                                            }}
                                          />

                                          <Row style={{ paddingTop: "10px" }}>
                                            <p style={{ marginBottom: "8px" }}>
                                              <b>
                                                Pagamento via Transferência
                                                Bancária*
                                              </b>
                                            </p>
                                            <p className="info">
                                              Faça a transferência bancária para
                                              o IBAN abaixo com a informação do
                                              item que está a pagar, envie o
                                              comprovativo para{" "}
                                              <a href="mailto:laura.santos@factorchave.pt">
                                                email
                                              </a>
                                            </p>
                                          </Row>
                                        </Space>
                                      </Row>
                                      <Row style={{ marginTop: "20px" }}>
                                        <Col span={24}>
                                          <Input
                                            readOnly
                                            value={valueBankTransferAccount}
                                            onClick={() =>
                                              copyToClipboard(
                                                valueBankTransferAccount.replace(
                                                  /\s/g,
                                                  ""
                                                )
                                              )
                                            }
                                            style={{
                                              cursor: "pointer !important",
                                              border:
                                                "0.20000000298023224px solid #707070",
                                              boxshadow:
                                                "0px 3px 6px #00000029",
                                              padding: "10px 15px",
                                            }}
                                            prefix={
                                              <CopyOutlined
                                                style={{
                                                  cursor: "pointer",
                                                  marginRight: "10px",
                                                  color: "#00000085",
                                                }}
                                              />
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    </Card>
                                  ) : (
                                    <Row
                                      align={"middle"}
                                      style={{
                                        border: "2px dashed #D6D7E2",
                                        borderRadius: "8px",
                                        height: "100%",
                                      }}
                                    >
                                      <p
                                        style={{
                                          color: "#B6B7C2",
                                          margin: "auto",
                                        }}
                                      >
                                        <i>Selecione a opção de pagamento</i>
                                      </p>
                                    </Row>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row gutter={[8, 8]} align={"middle"}>
                            <Col xs={24} sm={10}>
                              {/* {item.payment_accept_debito_direto == 1 && ( */}
                              <p className="info">
                                <i>
                                  *O pagamento via transferência carece de
                                  validação manual e poderá demorar algum tempo
                                  a validar.
                                </i>
                              </p>
                              {/* )} */}
                            </Col>
                            {/* {!pendent?.length > 0 && ( */}
                            <Col align="end" xs={24} sm={14}>
                              {paymentType !== "debit" && (
                                <>
                                  <Space>
                                    <Button
                                      className="cancelPayment"
                                      onClick={cancelPendent}
                                      loading={loadingsCancel[1]}
                                    >
                                      Cancelar pagamento
                                    </Button>
                                    <Button
                                      className="paymentSubmit"
                                      onClick={onConfirmOrCancelPayment(
                                        "confirm"
                                      )}
                                      loading={loadings[1]}
                                    >
                                      Confirmo que paguei
                                    </Button>
                                  </Space>
                                </>
                              )}
                            </Col>
                            {/* )} */}
                          </Row>
                        </Card>
                      </Row>
                    </>
                  ) : pendingPayment ? (
                    <>
                      <Row>
                        <PaymentAlert>
                          <Space size={18}>
                            <img
                              src={IconWarning}
                              alt="Pagamento pendente"
                              style={{ width: "26px" }}
                            />
                            <p>Pagamento pendente</p>
                          </Space>
                        </PaymentAlert>
                        <Card className="paymentAlert">
                          <Row>
                            <h3>{/* {item.title}  */}</h3>
                          </Row>
                          <Row>
                            <Col span={14}>
                              <p>
                                O seu perfil está assinalado como:{" "}
                                <b>{myUser.grau}</b>
                              </p>
                              <p style={{ marginTop: "5px" }}>
                                Para solicitar alteração envie um email para{" "}
                                <u>
                                  <a href="mailto:site@spap.pt?subject=Solicitar alteração Tipo de Sócio">
                                    site@spap.pt
                                  </a>
                                </u>
                              </p>
                            </Col>
                          </Row>
                          <Row gutter={32} style={{ marginTop: "30px" }}>
                            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                              <Card
                                className="white flex"
                                style={{ padding: "15px" }}
                              >
                                <p>Quotas por pagar</p>
                                <Select
                                  key={"select-quota"}
                                  id="select-4"
                                  placeholder="Escolha o seu método de pagamento"
                                  className="content__select"
                                  value={selectedOption}
                                  options={selectOptions}
                                  onChange={(e) =>
                                    handleAnosValorAPagar(
                                      e,
                                      anosAnterioresPendentes,
                                      myUser.quotas,
                                      currentPlan
                                    )
                                  }
                                />

                                <span className="paymentValue">
                                  {valorAPagar} €
                                </span>
                                <p>{descricaoPagamentoAMostrar}</p>
                                <PaymentBar />
                                <p>Total</p>
                                <p>
                                  <b>{currentPlan?.title}</b>
                                </p>
                              </Card>
                            </Col>
                            <Col xs={24} sm={24} md={16} lg={18} xl={18}>
                              <Row gutter={32} style={{ marginBottom: "20px" }}>
                                <Col>
                                  <b>Selecione a opção de pagamento</b>
                                </Col>
                              </Row>
                              <Row gutter={32}>
                                <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                                  <Row>
                                    <Radio.Group
                                      onChange={onChangeType}
                                      value={paymentType}
                                    >
                                      <Card className="white boxShadow radio">
                                        <Radio value={"mb"}>
                                          <img
                                            src={MultibancoImage}
                                            alt="Pagamento com Multibanco"
                                          />
                                        </Radio>
                                      </Card>
                                      <Card className="white boxShadow radio">
                                        <Radio value={"mbway"}>
                                          <img
                                            src={MBWayImage}
                                            alt="Pagamento com MBWay"
                                          />
                                        </Radio>
                                      </Card>
                                      <Card className="white boxShadow radio">
                                        <Radio value={"debit"}>
                                          <p>Transferência Bancária*</p>
                                        </Radio>
                                      </Card>
                                    </Radio.Group>
                                  </Row>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={16} xl={16}>
                                  {paymentType === "mb" ? (
                                    <Card className="white boxShadow">
                                      <Row>
                                        <p>
                                          <b>
                                            Dados fornecidos para pagamento:
                                          </b>
                                        </p>
                                      </Row>
                                      <Row
                                        style={{
                                          padding: "30px",
                                          backgroundColor: "#F3F6F9",
                                          marginTop: "20px",
                                        }}
                                      >
                                        <Col span={4}>
                                          <img
                                            src={MBImage}
                                            alt="Pagamento via MB"
                                          />
                                        </Col>
                                        <Col
                                          span={20}
                                          style={{ paddingLeft: "20px" }}
                                        >
                                          <p style={{ marginBottom: "8px" }}>
                                            <b>Entidade</b> A fornecer
                                          </p>
                                          <p style={{ marginBottom: "8px" }}>
                                            <b>Referência</b> A fornecer
                                          </p>
                                          <p>
                                            <b>Montante</b> {valorAPagar} €
                                          </p>
                                        </Col>
                                      </Row>
                                    </Card>
                                  ) : paymentType === "mbway" ? (
                                    <Card className="white boxShadow">
                                      <Row>
                                        <p>
                                          <b>Dados para pagamento:</b>
                                        </p>
                                      </Row>
                                      <Row
                                        style={{
                                          padding: "30px",
                                          backgroundColor: "#F3F6F9",
                                          marginTop: "20px",
                                        }}
                                      >
                                        <Col span={4}>
                                          <img
                                            src={MBImage}
                                            alt="Pagamento via MB"
                                          />
                                        </Col>
                                        <Col
                                          span={20}
                                          style={{ paddingLeft: "20px" }}
                                        >
                                          <p style={{ marginBottom: "8px" }}>
                                            <b>Número de telemóvel</b>
                                          </p>
                                          <Input
                                            placeholder="Número de telemóvel"
                                            value={valueTel}
                                            onChange={handleChangeTel}
                                            style={{
                                              marginBottom: "8px",
                                              padding: "10px 8px",
                                              border: "2px solid #C2C4D4",
                                              background: "#D9E1E700",
                                            }}
                                          />
                                          <p>
                                            <b>Quantia</b> {valorAPagar} €
                                          </p>
                                        </Col>
                                      </Row>
                                    </Card>
                                  ) : paymentType === "debit" ? (
                                    <Card className="white boxShadow">
                                      <Row>
                                        <Space size={18}>
                                          <img
                                            src={BankTransferImage}
                                            alt="Pagamento com transferencia bancária"
                                            style={{
                                              width: "26px",
                                              height: "26px",
                                            }}
                                          />

                                          <Row style={{ paddingTop: "10px" }}>
                                            <p style={{ marginBottom: "8px" }}>
                                              <b>
                                                Pagamento via Transferência
                                                Bancária*
                                              </b>
                                            </p>
                                            <p className="info">
                                              Faça a transferência bancária para
                                              o IBAN abaixo com a informação do
                                              item que está a pagar, envie o
                                              comprovativo para{" "}
                                              <a href="mailto:laura.santos@factorchave.pt">
                                                email
                                              </a>
                                            </p>
                                          </Row>
                                        </Space>
                                      </Row>
                                      <Row style={{ marginTop: "20px" }}>
                                        <Col span={24}>
                                          <Input
                                            readOnly
                                            value={valueBankTransferAccount}
                                            onClick={() =>
                                              copyToClipboard(
                                                valueBankTransferAccount.replace(
                                                  /\s/g,
                                                  ""
                                                )
                                              )
                                            }
                                            style={{
                                              cursor: "pointer !important",
                                              border:
                                                "0.20000000298023224px solid #707070",
                                              boxshadow:
                                                "0px 3px 6px #00000029",
                                              padding: "10px 15px",
                                            }}
                                            prefix={
                                              <CopyOutlined
                                                style={{
                                                  cursor: "pointer",
                                                  marginRight: "10px",
                                                  color: "#00000085",
                                                }}
                                              />
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    </Card>
                                  ) : (
                                    <Row
                                      align={"middle"}
                                      style={{
                                        border: "2px dashed #D6D7E2",
                                        borderRadius: "8px",
                                        height: "100%",
                                      }}
                                    >
                                      <p
                                        style={{
                                          color: "#B6B7C2",
                                          margin: "auto",
                                        }}
                                      >
                                        <i>Selecione a opção de pagamento</i>
                                      </p>
                                    </Row>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row gutter={32} align={"middle"}>
                            <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                              {/* {item.payment_accept_debito_direto == 1 && ( */}
                              <p className="info">
                                <i>
                                  *O pagamento via transferência carece de
                                  validação manual e poderá demorar algum tempo
                                  a validar.
                                </i>
                              </p>
                              {/* )} */}
                            </Col>
                            {/* {!pendent?.length > 0 && ( */}
                            <Col
                              xs={24}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              align={"end"}
                            >
                              {paymentType !== "debit" && (
                                <Button
                                  className="paymentSubmit"
                                  onClick={() => {
                                    let newItemPayment = [];
                                    newItemPayment.planId = 1;
                                    newItemPayment.quotasPorPagar =
                                      quotasPorPagar;
                                    newItemPayment.price = valorAPagar;
                                    newItemPayment.planDescription =
                                      descricaoPagamento;
                                    newItemPayment.tipo = selectedOption;
                                    setItemPayment(newItemPayment);
                                    setPaymentOptions(true);
                                    setPendingPayment(true);
                                  }}
                                  disabled={
                                    paymentType === null ||
                                    (paymentType === "mbway" && valueTel === "")
                                      ? true
                                      : false
                                  }
                                  style={{
                                    opacity:
                                      (paymentType === null ||
                                        (paymentType === "mbway" &&
                                          valueTel === "")) &&
                                      ".5",
                                  }}
                                >
                                  Efetuar pagamento
                                </Button>
                              )}
                            </Col>
                            {/* )} */}
                          </Row>
                        </Card>
                      </Row>
                    </>
                  ) : pendentESP == true ? (
                    <>
                      <div style={{ paddingBottom: "60px" }}>
                        <PaymentAlert
                          style={{
                            marginBottom: "30px",
                          }}
                        >
                          <Space size={18}>
                            <img
                              src={IconWarning}
                              alt="Pagamento pendente"
                              style={{ width: "26px" }}
                            />
                            <p>
                              Você tem uma quota do tipo ESP{" "}
                              <u>{optionalPayment}</u> para pagar. Entre em
                              contacto connosco para atualizar as suas quotas
                            </p>
                          </Space>
                        </PaymentAlert>
                      </div>
                    </>
                  ) : (
                    <div style={{ paddingBottom: "60px" }}>
                      <PaymentAlert
                        style={{
                          backgroundColor: "#aaeeaa",
                          marginBottom: "30px",
                        }}
                      >
                        <Space size={18}>
                          <CheckCircleOutlined />
                          <p>Você não tem faturas pendentes a pagar</p>
                        </Space>
                      </PaymentAlert>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
            <Col xl={5} xs={24}>
              <SidebarMenu navItens={SidebarMenuLinks} alert={1} />
            </Col>
          </Row>
        </Container>

        <RecommendedLinks
          title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
          links={[
            {
              title: "Lista de sócios",
              link: "/socios/lista-de-socios",
            },
            {
              title: "Pedir uma 2ª opinião",
              link: "/ferramentas-uteis/pedir-uma-segunda-opiniao",
            },
            {
              title: "Área pessoal",
              link: "/socios/area-de-socio",
            },
            {
              title: "Contactos",
              link: "/spap/contactos",
            },
          ]}
        />
      </Wrapper>
    </>
  );
}
