const endpoints = {
  auth: {
      login: 'auth/login',
      register: 'auth/register',
      verifyUser: 'auth/verifyUser',
      verifyToken: 'auth/verifyToken',
      renewToken: 'auth/renewToken',
  },
  purchase: {
      getPurchases: 'getPurchases',
      purchaseMB: 'purchaseMB',
      purchaseMBWay: 'purchaseMBWay',
      postPurchase: 'postPurchase',
      getPrices: 'getPrices',
      updateUserPaymentMethod: 'updateUserPaymentMethod',
      verifyPurchaseDebit: 'verifyPurchaseDebit',
      paymentUserCancel: 'paymentUserCancel',
      updateUserPaymentStatus: 'updateUserPaymentStatus'
  },
  info: {
      getConfig: 'info/getConfig',
      getInfo: 'info/getInfo',
      getDashboardData: 'info/getDashboardData',
  },
  event: {
      getEvents: 'event/getEvents',
      getEventsByUserId: 'event/getEventsByUserId',
      getProfessions: 'event/getProfessions',
      addNewEvent: 'event/addNewEvent',
      getUsersByEventId: 'event/getUsersByEventId',
  },
  app: {
      getUser: 'app/user/getUser',
      getUserProfile: 'app/user/getUserProfile',
      getTokenData: 'auth/getTokenData',
      getUsers: 'app/users/getUsers',
      getUserAdminPayments: 'app/user/getUserAdminPayments',
      getUserAdmin: 'app/user/getUserAdmin',
      editUser: 'app/user/editUser',
      updateUserRegularization: 'app/user/updateUserRegularization',
      postFormDepartment: 'app/user/postFormDepartment',
  }
}

export default endpoints;