import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import FlexInColumn from "../../FlexInColumn";

import { ReactComponent as LogoSpap } from "../../../Assets/Images/Backoffice/Main-Menu/SPAP.svg";
/* Menu Action Icons */
import { ReactComponent as IconMenuOpen } from "../../../Assets/Images/Backoffice/Main-Menu/Menu-Open.svg";
import { ReactComponent as IconMenuClose } from "../../../Assets/Images/Backoffice/Main-Menu/Menu-Close.svg";
/* Nav Icons */
import { ReactComponent as IconDashboard } from "../../../Assets/Images/Backoffice/Main-Menu/Dashboard.svg";
import { ReactComponent as IconDashboardOn } from "../../../Assets/Images/Backoffice/Main-Menu/Dashboard-ON.svg";
import { ReactComponent as IconQuotas } from "../../../Assets/Images/Backoffice/Main-Menu/Quotas.svg";
import { ReactComponent as IconQuotasOn } from "../../../Assets/Images/Backoffice/Main-Menu/Quotas-ON.svg";
import { ReactComponent as IconCertificados } from "../../../Assets/Images/Backoffice/Main-Menu/Certificados.svg";
import { ReactComponent as IconCertificadosOn } from "../../../Assets/Images/Backoffice/Main-Menu/Certificados-ON.svg";
import { ReactComponent as IconClubes } from "../../../Assets/Images/Backoffice/Main-Menu/Clubes.svg";
import { ReactComponent as IconClubesOn } from "../../../Assets/Images/Backoffice/Main-Menu/Clubes-ON.svg";
import { ReactComponent as IconAnticorpos } from "../../../Assets/Images/Backoffice/Main-Menu/Anticorpos.svg";
import { ReactComponent as IconAnticorposOn } from "../../../Assets/Images/Backoffice/Main-Menu/Anticorpos-ON.svg";
import { ReactComponent as IconSocios } from "../../../Assets/Images/Backoffice/Main-Menu/Socios.svg";
import { ReactComponent as IconSociosOn } from "../../../Assets/Images/Backoffice/Main-Menu/Socios-ON.svg";
import { ReactComponent as IconPatologistas } from "../../../Assets/Images/Backoffice/Main-Menu/Patologistas.svg";
import { ReactComponent as IconPatologistasOn } from "../../../Assets/Images/Backoffice/Main-Menu/Patologistas-ON.svg";
import { ReactComponent as IconCasoDoMes } from "../../../Assets/Images/Backoffice/Main-Menu/Caso-do-mes.svg";
import { ReactComponent as IconCasoDoMesOn } from "../../../Assets/Images/Backoffice/Main-Menu/Caso-do-mes-ON.svg";
import { ReactComponent as IconEventos } from "../../../Assets/Images/Backoffice/Main-Menu/Eventos-Off.svg";
import { ReactComponent as IconEventosOn } from "../../../Assets/Images/Backoffice/Main-Menu/Eventos-On.svg";
import { ReactComponent as IconFicheiros } from "../../../Assets/Images/Backoffice/Main-Menu/Ficheiros-Off.svg";
import { ReactComponent as IconFicheirosOn } from "../../../Assets/Images/Backoffice/Main-Menu/Ficheiros-On.svg";
import { ReactComponent as IconBanner } from "../../../Assets/Images/Backoffice/Main-Menu/Banner-Off.svg";
import { ReactComponent as IconBannerOn } from "../../../Assets/Images/Backoffice/Main-Menu/Banner-On.svg";
import { ReactComponent as IconLogs } from "../../../Assets/Images/Backoffice/Main-Menu/Logs-Off.svg";
import { ReactComponent as IconLogsOn } from "../../../Assets/Images/Backoffice/Main-Menu/Logs-On.svg";
import { ReactComponent as IconNewsletter } from "../../../Assets/Images/Backoffice/Main-Menu/Newsletter-Off.svg";
import { ReactComponent as IconNewsletterOn } from "../../../Assets/Images/Backoffice/Main-Menu/Newsletter-On.svg";
import { ReactComponent as IconApresentacoes } from "../../../Assets/Images/Backoffice/Main-Menu/Apresentacoes-Off.svg";
import { ReactComponent as IconApresentacoesOn } from "../../../Assets/Images/Backoffice/Main-Menu/Apresentacoes-On.svg";
import { ReactComponent as IconRevista } from "../../../Assets/Images/Backoffice/Main-Menu/Revista-Off.svg";
import { ReactComponent as IconRevistaOn } from "../../../Assets/Images/Backoffice/Main-Menu/Revista-On.svg";
/* Nav Notifi Icons */
import { ReactComponent as IconPerfil } from "../../../Assets/Images/Backoffice/Main-Menu/Perfil.svg";
import { ReactComponent as IconPerfilOn } from "../../../Assets/Images/Backoffice/Main-Menu/Perfil-ON.svg";
import { ReactComponent as IconMsg } from "../../../Assets/Images/Backoffice/Main-Menu/Mensagem.svg";
import { ReactComponent as IconMsgOn } from "../../../Assets/Images/Backoffice/Main-Menu/Mensagem-ON.svg";
import { ReactComponent as IconLogout } from "../../../Assets/Images/Backoffice/Main-Menu/Logout.svg";
import { ReactComponent as IconLogoutOn } from "../../../Assets/Images/Backoffice/Main-Menu/Logout-ON.svg";

const Wrapper = styled.section`
  background-color: ${(props) => props.theme.blueLight};
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr;
  gap: 2.083vw;
  height: 100vh;
  padding: 2.188vw 1.979vw;
  position: fixed;
  width: 7.083vw;
  transition: 0.3s ease;
  z-index: 888;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(73, 77, 125, 0.8);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Cor da barra de rolagem ao passar o mouse */
  }

  @media (max-width: 992px) {
  }

  ${(props) =>
    props.openMenu &&
    `
    width: 20.833vw;
  `}
`;

const Header = styled.div`
  .icon {
    cursor: pointer;
    place-self: center;
    width: 3.125vw;
    height: 100%;
  }

  .logo {
    animation: fadeIn 1s;
    width: 12.656vw;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Bar = styled.div`
  background-color: #8688a8;
  height: 1px;
  width: 100%;
`;

const Nav = styled.nav`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.083vw;

  .item {
    color: #c2c4d4;
    place-self: center;
    text-decoration: none;

    svg {
      height: 1.042vw;
      width: 1.042vw;
    }

    ${(props) =>
      props.openMenu &&
      `
      align-items: center;  
      display: grid;
      justify-content: flex-start;
      grid-template-columns: 20px 1fr;
      gap: 20px;
      place-self: flex-start;
    `}
  }

  .item.selected {
    color: #ffffff;
  }
`;

export default function Sidebar(props) {
  const location = useLocation();

  const [openMenu, setOpenMenu] = useState(false);

  const navItens = [
    {
      icon: <IconQuotas />,
      iconOn: <IconQuotasOn />,
      text: "Quotas",
      link: "quotas",
    },
    {
      icon: <IconClubes />,
      iconOn: <IconClubesOn />,
      text: "Clubes",
      link: "todos-clubes",
    },
    {
      icon: <IconAnticorpos />,
      iconOn: <IconAnticorposOn />,
      text: "Anticorpos",
      link: "anticorpos",
    },
    {
      icon: <IconSocios />,
      iconOn: <IconSociosOn />,
      text: "Sócios",
      link: "socios",
    },
    /*
    {
      'icon' : <IconPatologistas />,
      'iconOn' : <IconPatologistasOn />,
      'text' : 'Patologistas',
      'link' : 'patologistas'
    },
    */
    {
      icon: <IconEventos />,
      iconOn: <IconEventosOn />,
      text: "Eventos",
      link: "eventos",
    },
    {
      icon: <IconCasoDoMes />,
      iconOn: <IconCasoDoMesOn />,
      text: "Caso do mês",
      link: "caso-do-mes",
    },
    {
      icon: <IconBanner />,
      iconOn: <IconBannerOn />,
      text: "Banner",
      link: "banner",
    },
    {
      icon: <IconFicheiros />,
      iconOn: <IconFicheirosOn />,
      text: "Estatutos e Documentos",
      link: "estatutos-e-documentos",
    },
    {
      icon: <IconNewsletter />,
      iconOn: <IconNewsletterOn />,
      text: "Newsletters",
      link: "newsletters",
    },
    {
      icon: <IconRevista />,
      iconOn: <IconRevistaOn />,
      text: "Revistas",
      link: "revistas",
    },
    {
      icon: <IconLogs />,
      iconOn: <IconLogsOn />,
      text: "Logs",
      link: "logs",
    },
    {
      icon: <IconFicheiros />,
      iconOn: <IconFicheirosOn />,
      text: "Ficheiros",
      link: "ficheiros",
    },
    {
      icon: <IconApresentacoes />,
      iconOn: <IconApresentacoesOn />,
      text: "Apresentações",
      link: "apresentacoes",
    },
  ];

  const navItensNotifi = [
    {
      icon: <IconPerfil />,
      iconOn: <IconPerfilOn />,
      text: "Perfil",
      link: "perfil",
    },
    {
      icon: <IconMsg />,
      iconOn: <IconMsgOn />,
      text: "Mensagens",
      link: "mensagens",
    },
    {
      icon: <IconLogout />,
      iconOn: <IconLogoutOn />,
      text: "Sair",
      link: "sair",
    },
  ];

  return (
    <Wrapper openMenu={openMenu}>
      <Header>
        {openMenu ? (
          <FlexInColumn gap="1.042vw">
            <LogoSpap className="logo" />
            <IconMenuClose
              className="icon"
              onClick={() => setOpenMenu(false)}
            />
          </FlexInColumn>
        ) : (
          <IconMenuOpen className="icon" onClick={() => setOpenMenu(true)} />
        )}
      </Header>
      <Bar key={"bar-side"} />
      <Nav openMenu={openMenu}>
        <Link
          to={"/dashboard"}
          className={
            "item item-0" +
            (!location.pathname.split("/")[2] ? " selected" : "")
          }
        >
          {location.pathname.split("/")[2] ? (
            <IconDashboard />
          ) : (
            <IconDashboardOn />
          )}
          {openMenu && "Dashboard"}
        </Link>
        {navItens.map((item, index) => {
          return (
            <Link
              key={"navitem-" + index}
              to={"/dashboard/" + item.link}
              className={
                "item item-" +
                (index + 1) +
                (location.pathname.includes(item.link) ? " selected" : "")
              }
            >
              {location.pathname.includes(item.link) ? item.iconOn : item.icon}
              {openMenu && item.text}
            </Link>
          );
        })}
      </Nav>
      <Bar />
      <Nav openMenu={openMenu}>
        {navItensNotifi.map((item, index) => {
          if (item.link != "sair") {
            return (
              <Link
                key={"notif-" + index}
                to={"/dashboard/" + item.link}
                className={
                  "item item-" +
                  (index + 1) +
                  (location.pathname.includes(item.link) ? " selected" : "")
                }
              >
                {location.pathname.includes(item.link)
                  ? item.iconOn
                  : item.icon}
                {openMenu && item.text}
              </Link>
            );
          } else {
            return (
              <Link
                key={"notif-" + index}
                to={"/logout/"}
                className={
                  "item item-" +
                  (index + 1) +
                  (location.pathname.includes(item.link) ? " selected" : "")
                }
              >
                {location.pathname.includes(item.link)
                  ? item.iconOn
                  : item.icon}
                {openMenu && item.text}
              </Link>
            );
          }
        })}
      </Nav>
    </Wrapper>
  );
}
