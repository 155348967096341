import React, { useDebugdefaultValue } from "react";
import { InboxOutlined } from "@ant-design/icons";
import {
  Button,
  message,
  Switch,
  Divider,
  Row,
  Col,
  Modal,
  Input,
  Upload,
  Select,
  Typography,
} from "antd";
const { Dragger } = Upload;
const { Text } = Typography;
const { TextArea } = Input;
import { useState } from "react";

var styles = {
  h6_label: {
    color: "#809FB8",
    fontFamily: "Roboto",
    fontSize: "15px",
    marginBottom: "1PX",
  },
  input_class: {
    width: "100%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
  },
  select_class: {
    borderRadius: "6px",
    height: "45px",
    color: "#0C1151",
    width: "100%",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
  input_class2: {
    width: "100%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #ccc",
    color: "#0C1151",
  },
};

export default function FirstTab(props) {
  let userDrawer = [];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const setCapitalize = (str) => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  };
  const [instituicaoOptions, setInstituicaoOptions] = useState(() => {
    let inst = [
      { value: "ARS ALGARVE", label: "ARS ALGARVE" },
      {
        value: "BRITISH HOSPITAL LISBON XXI, S.A",
        label: "BRITISH HOSPITAL LISBON XXI, S.A",
      },
      { value: "CEDAP", label: "CEDAP" },
      {
        value: "CENTRO DE MEDICINA E REABILITAÇÃO DE ALCOITÃO",
        label: "CENTRO DE MEDICINA E REABILITAÇÃO DE ALCOITÃO",
      },
      {
        value: "CENTRO DE SAÚDE DE OLEIROS",
        label: "CENTRO DE SAÚDE DE OLEIROS",
      },
      {
        value: "CENTRO DE SAÚDE PEDRÓGÃO GRANDE",
        label: "CENTRO DE SAÚDE PEDRÓGÃO GRANDE",
      },
      {
        value: "CENTRO HOSPITALAR ALTO AVE E.P.E.",
        label: "CENTRO HOSPITALAR ALTO AVE E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR ALTO MINHO E.P.E.",
        label: "CENTRO HOSPITALAR ALTO MINHO E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR BAIXO ALENTEJO E.P.E.",
        label: "CENTRO HOSPITALAR BAIXO ALENTEJO E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR BAIXO VOUGA",
        label: "CENTRO HOSPITALAR BAIXO VOUGA",
      },
      {
        value: "CENTRO HOSPITALAR BARLAVENTO ALGARVIO, E.P.E.",
        label: "CENTRO HOSPITALAR BARLAVENTO ALGARVIO, E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR BARREIRO MONTIJO E.P.E.",
        label: "CENTRO HOSPITALAR BARREIRO MONTIJO E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR CALDAS DA RAINHA E.P.E.",
        label: "CENTRO HOSPITALAR CALDAS DA RAINHA E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR CASCAIS E.P.E.",
        label: "CENTRO HOSPITALAR CASCAIS E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR COVA DA BEIRA, E.P.E.",
        label: "CENTRO HOSPITALAR COVA DA BEIRA, E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR DE LEIRIA, E.P.E.",
        label: "CENTRO HOSPITALAR DE LEIRIA, E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR DO ALGARVE",
        label: "CENTRO HOSPITALAR DO ALGARVE",
      },
      {
        value: "CENTRO HOSPITALAR DO FUNCHAL",
        label: "CENTRO HOSPITALAR DO FUNCHAL",
      },
      {
        value: "CENTRO HOSPITALAR DO OESTE",
        label: "CENTRO HOSPITALAR DO OESTE",
      },
      {
        value: "CENTRO HOSPITALAR E UNIVERSITÁRIO DE COIMBRA",
        label: "CENTRO HOSPITALAR E UNIVERSITÁRIO DE COIMBRA",
      },
      {
        value: "CENTRO HOSPITALAR E UNIVERSITÁRIO DE SÃO JOÃO E.P.E.",
        label: "CENTRO HOSPITALAR E UNIVERSITÁRIO DE SÃO JOÃO E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR E UNIVERSITÁRIO DO ALGARVE",
        label: "CENTRO HOSPITALAR E UNIVERSITÁRIO DO ALGARVE",
      },
      {
        value: "CENTRO HOSPITALAR E UNIVERSITÁRIO DO PORTO E.P.E.",
        label: "CENTRO HOSPITALAR E UNIVERSITÁRIO DO PORTO E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR ENTRE DOURO E VOUGA E.P.E",
        label: "CENTRO HOSPITALAR ENTRE DOURO E VOUGA E.P.E",
      },
      {
        value: "CENTRO HOSPITALAR LISBOA OCIDENTAL E.P.E.",
        label: "CENTRO HOSPITALAR LISBOA OCIDENTAL E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR MÉDIO - TEJO  E.P.E.",
        label: "CENTRO HOSPITALAR MÉDIO - TEJO  E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR MÉDIO AVE, E.P.E.",
        label: "CENTRO HOSPITALAR MÉDIO AVE, E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR MUNDIAL CONFIANÇA - ÉVORA E.P.E.",
        label: "CENTRO HOSPITALAR MUNDIAL CONFIANÇA - ÉVORA E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR NORDESTE, E.P.E.",
        label: "CENTRO HOSPITALAR NORDESTE, E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR PÓVOA DO VARZIM/VILA CONDE E.P.E.",
        label: "CENTRO HOSPITALAR PÓVOA DO VARZIM/VILA CONDE E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR PSIQUIATRICO DE COIMBRA",
        label: "CENTRO HOSPITALAR PSIQUIATRICO DE COIMBRA",
      },
      {
        value: "CENTRO HOSPITALAR PSIQUIÁTRICO DE LISBOA E.P.E.",
        label: "CENTRO HOSPITALAR PSIQUIÁTRICO DE LISBOA E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR S. FRANCISCO,  E.P.E.",
        label: "CENTRO HOSPITALAR S. FRANCISCO,  E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR SETÚBAL, E.P.E.",
        label: "CENTRO HOSPITALAR SETÚBAL, E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR TÂMEGA E SOUSA E.P.E.",
        label: "CENTRO HOSPITALAR TÂMEGA E SOUSA E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR TONDELA VISEU E.P.E",
        label: "CENTRO HOSPITALAR TONDELA VISEU E.P.E",
      },
      {
        value: "CENTRO HOSPITALAR TRÁS OS MONTES E ALTO DOURO E.P.E",
        label: "CENTRO HOSPITALAR TRÁS OS MONTES E ALTO DOURO E.P.E",
      },
      {
        value: "CENTRO HOSPITALAR UNIVERSITÁRIO DE COIMBRA",
        label: "CENTRO HOSPITALAR UNIVERSITÁRIO DE COIMBRA",
      },
      {
        value: "CENTRO HOSPITALAR UNIVERSITÁRIO DE SANTO ANTÓNIO",
        label: "CENTRO HOSPITALAR UNIVERSITÁRIO DE SANTO ANTÓNIO",
      },
      {
        value: "CENTRO HOSPITALAR UNIVERSITÁRIO LISBOA CENTRAL E.P.E.",
        label: "CENTRO HOSPITALAR UNIVERSITÁRIO LISBOA CENTRAL E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR UNIVERSITÁRIO LISBOA NORTE, E.P.E.",
        label: "CENTRO HOSPITALAR UNIVERSITÁRIO LISBOA NORTE, E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR VILA NOVA DE GAIA / ESPINHO E.P.E.",
        label: "CENTRO HOSPITALAR VILA NOVA DE GAIA / ESPINHO E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR VILA REAL/PESO DA RÉGUA S.ª E.P.E.",
        label: "CENTRO HOSPITALAR VILA REAL/PESO DA RÉGUA S.ª E.P.E.",
      },
      {
        value: "CLÍNICA DE STO ANTÓNIO",
        label: "CLÍNICA DE STO ANTÓNIO",
      },
      {
        value: "CLÍNICA JOAQUIM CHAVES",
        label: "CLÍNICA JOAQUIM CHAVES",
      },
      {
        value: "CLÍNICA LUSÍADAS ALMADA",
        label: "CLÍNICA LUSÍADAS ALMADA",
      },
      {
        value: "CLÍNICA LUSÍADAS FARO",
        label: "CLÍNICA LUSÍADAS FARO",
      },
      {
        value: "CLÍNICA LUSÍADAS FÓRUM ALGARVE",
        label: "CLÍNICA LUSÍADAS FÓRUM ALGARVE",
      },
      {
        value: "CLÍNICA LUSÍADAS GAIA",
        label: "CLÍNICA LUSÍADAS GAIA",
      },
      {
        value: "CLÍNICA LUSÍADAS ORIENTE",
        label: "CLÍNICA LUSÍADAS ORIENTE",
      },
      {
        value: "CLÍNICA LUSÍADAS SACAVÉM",
        label: "CLÍNICA LUSÍADAS SACAVÉM",
      },
      {
        value: "CLÍNICA MÉDICA DO PADRÃO",
        label: "CLÍNICA MÉDICA DO PADRÃO",
      },
      {
        value: "CLÍNICA MÉDICA POVOA DO VARZIM - CLIPÓVOA",
        label: "CLÍNICA MÉDICA POVOA DO VARZIM - CLIPÓVOA",
      },
      {
        value: "CUF ALMADA CLÍNICA",
        label: "CUF ALMADA CLÍNICA",
      },
      {
        value: "CUF ALVALADE CLÍNICA",
        label: "CUF ALVALADE CLÍNICA",
      },
      {
        value: "CUF BELÉM CLÍNICA",
        label: "CUF BELÉM CLÍNICA",
      },
      {
        value: "CUF CASCAIS HOSPITAL",
        label: "CUF CASCAIS HOSPITAL",
      },
      {
        value: "CUF COIMBRA HOSPITAL",
        label: "CUF COIMBRA HOSPITAL",
      },
      {
        value: "CUF DESCOBERTAS HOSPITAL",
        label: "CUF DESCOBERTAS HOSPITAL",
      },
      {
        value: "CUF INFANTE SANTO HOSPITAL",
        label: "CUF INFANTE SANTO HOSPITAL",
      },
      {
        value: "CUF MAFRA CLÍNICA",
        label: "CUF MAFRA CLÍNICA",
      },
      {
        value: "CUF MIRAFLORES CLÍNICA",
        label: "CUF MIRAFLORES CLÍNICA",
      },
      {
        value: "CUF PORTO HOSPITAL",
        label: "CUF PORTO HOSPITAL",
      },
      {
        value: "CUF PORTO INSTITUTO",
        label: "CUF PORTO INSTITUTO",
      },
      {
        value: "CUF S. DOMINGOS RANA CLÍNICA",
        label: "CUF S. DOMINGOS RANA CLÍNICA",
      },
      {
        value: "CUF S. JOÃO DA MADEIRA CLÍNICA",
        label: "CUF S. JOÃO DA MADEIRA CLÍNICA",
      },
      {
        value: "CUF SANTARÉM HOSPITAL",
        label: "CUF SANTARÉM HOSPITAL",
      },
      {
        value: "CUF SINTRA CLÍNICA",
        label: "CUF SINTRA CLÍNICA",
      },
      { value: "CUF TEJO", label: "CUF TEJO" },
      {
        value: "CUF TORRES VEDRAS HOSPITAL",
        label: "CUF TORRES VEDRAS HOSPITAL",
      },
      {
        value: "CUF VISEU HOSPITAL",
        label: "CUF VISEU HOSPITAL",
      },
      {
        value: "FACULDADE DE MEDICINA DO PORTO",
        label: "FACULDADE DE MEDICINA DO PORTO",
      },
      {
        value: "FUNDAÇÃO CHAMPALIMAUD",
        label: "FUNDAÇÃO CHAMPALIMAUD",
      },
      {
        value: "GERMANO DE SOUSA - LABORATÓRIO DE ANÁLISES CLÍNICAS",
        label: "GERMANO DE SOUSA - LABORATÓRIO DE ANÁLISES CLÍNICAS",
      },
      {
        value: "HOSPITAIS PRIVADOS DE PORTUGAL - PORTO",
        label: "HOSPITAIS PRIVADOS DE PORTUGAL - PORTO",
      },
      {
        value: "HOSPITAL AGOSTINHO RIBEIRO",
        label: "HOSPITAL AGOSTINHO RIBEIRO",
      },
      {
        value: "Hospital Albert Einstein",
        label: "Hospital Albert Einstein",
      },
      {
        value: "HOSPITAL AMATO LUSITANO DE CASTELO BRANCO",
        label: "HOSPITAL AMATO LUSITANO DE CASTELO BRANCO",
      },
      {
        value: "HOSPITAL ARCEBISPO JOÃO CRISÓSTOMO-CANTANHEDE",
        label: "HOSPITAL ARCEBISPO JOÃO CRISÓSTOMO-CANTANHEDE",
      },
      {
        value: "HOSPITAL BARLAVENTO ALGARVIO, E.P.E.",
        label: "HOSPITAL BARLAVENTO ALGARVIO, E.P.E.",
      },
      {
        value: "HOSPITAL BEATRIZ ÂNGELO",
        label: "HOSPITAL BEATRIZ ÂNGELO",
      },
      {
        value: "HOSPITAL BERNARDINO LOPES DE OLIVEIRA - ALCOBAÇA",
        label: "HOSPITAL BERNARDINO LOPES DE OLIVEIRA - ALCOBAÇA",
      },
      {
        value: "HOSPITAL Cª SEG. MUNDIAL CONFIANÇA (PORTO)",
        label: "HOSPITAL Cª SEG. MUNDIAL CONFIANÇA (PORTO)",
      },
      {
        value: "HOSPITAL CÂNDIDO DE FIGUEIREDO -TONDELA",
        label: "HOSPITAL CÂNDIDO DE FIGUEIREDO -TONDELA",
      },
      {
        value: "HOSPITAL CENTRAL DO FUNCHAL",
        label: "HOSPITAL CENTRAL DO FUNCHAL",
      },
      {
        value: "HOSPITAL CRUZ VERMELHA PORTUGUESA",
        label: "HOSPITAL CRUZ VERMELHA PORTUGUESA",
      },
      {
        value: "HOSPITAL CURRY CABRAL",
        label: "HOSPITAL CURRY CABRAL",
      },
      {
        value: "HOSPITAL D. ESTEFÂNIA",
        label: "HOSPITAL D. ESTEFÂNIA",
      },
      {
        value: "HOSPITAL DA LIGA DE AMIGOS DOS HOSPITAIS - LISBOA",
        label: "HOSPITAL DA LIGA DE AMIGOS DOS HOSPITAIS - LISBOA",
      },
      {
        value: "HOSPITAL DA LUZ ARRÁBIDA",
        label: "HOSPITAL DA LUZ ARRÁBIDA",
      },
      {
        value: "HOSPITAL DA LUZ AVEIRO",
        label: "HOSPITAL DA LUZ AVEIRO",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DA AMADORA",
        label: "HOSPITAL DA LUZ CLÍNICA DA AMADORA",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DE ÁGUEDA",
        label: "HOSPITAL DA LUZ CLÍNICA DE ÁGUEDA",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DE AMARANTE",
        label: "HOSPITAL DA LUZ CLÍNICA DE AMARANTE",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DE CERVEIRA",
        label: "HOSPITAL DA LUZ CLÍNICA DE CERVEIRA",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DE ODIVELAS",
        label: "HOSPITAL DA LUZ CLÍNICA DE ODIVELAS",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DE OEIRAS",
        label: "HOSPITAL DA LUZ CLÍNICA DE OEIRAS",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DE OIÃ",
        label: "HOSPITAL DA LUZ CLÍNICA DE OIÃ",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DE VILA REAL",
        label: "HOSPITAL DA LUZ CLÍNICA DE VILA REAL",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DO PORTO",
        label: "HOSPITAL DA LUZ CLÍNICA DO PORTO",
      },
      {
        value: "HOSPITAL DA LUZ GUIMARÃES",
        label: "HOSPITAL DA LUZ GUIMARÃES",
      },
      {
        value: "HOSPITAL DA LUZ LISBOA",
        label: "HOSPITAL DA LUZ LISBOA",
      },
      {
        value: "HOSPITAL DA LUZ PÓVOA DE VARZIM",
        label: "HOSPITAL DA LUZ PÓVOA DE VARZIM",
      },
      {
        value: "HOSPITAL DA LUZ SETÚBAL",
        label: "HOSPITAL DA LUZ SETÚBAL",
      },
      {
        value: "HOSPITAL DA MARINHA",
        label: "HOSPITAL DA MARINHA",
      },
      {
        value: "HOSPITAL DA MISERICÓRDIA DE ÉVORA",
        label: "HOSPITAL DA MISERICÓRDIA DE ÉVORA",
      },
      {
        value: "HOSPITAL DA ORDEM TERCEIRA SÃO FRANCISCO DO PORTO",
        label: "HOSPITAL DA ORDEM TERCEIRA SÃO FRANCISCO DO PORTO",
      },
      {
        value: "HOSPITAL DA PRELADA",
        label: "HOSPITAL DA PRELADA",
      },
      {
        value: "HOSPITAL DA SANTA CASA DA MISERICORDIA DE PONTE DA BARCA",
        label: "HOSPITAL DA SANTA CASA DA MISERICORDIA DE PONTE DA BARCA",
      },
      {
        value: "HOSPITAL DA V. ORDEM TERCEIRA",
        label: "HOSPITAL DA V. ORDEM TERCEIRA",
      },
      {
        value: "HOSPITAL DAS FORÇAS ARMADAS/ FORÇA AÉREA",
        label: "HOSPITAL DAS FORÇAS ARMADAS/ FORÇA AÉREA",
      },
      {
        value: "HOSPITAL DE ALCANENA",
        label: "HOSPITAL DE ALCANENA",
      },
      {
        value: "HOSPITAL DE BRAGA",
        label: "HOSPITAL DE BRAGA",
      },
      {
        value: "HOSPITAL DE CASCAIS (PPP)",
        label: "HOSPITAL DE CASCAIS (PPP)",
      },
      {
        value: "HOSPITAL DE CASCAIS DR. JOSÉ DE ALMEIDA",
        label: "HOSPITAL DE CASCAIS DR. JOSÉ DE ALMEIDA",
      },
      {
        value: "HOSPITAL DE JESUS",
        label: "HOSPITAL DE JESUS",
      },
      {
        value: "HOSPITAL DE LOULÉ",
        label: "HOSPITAL DE LOULÉ",
      },
      {
        value: "HOSPITAL DE MAGALHÃES  LEMOS",
        label: "HOSPITAL DE MAGALHÃES  LEMOS",
      },
      {
        value: "HOSPITAL DE SANTA CRUZ",
        label: "HOSPITAL DE SANTA CRUZ",
      },
      {
        value: "Hospital de Santarém",
        label: "Hospital de Santarém",
      },
      {
        value: "HOSPITAL DE SÃO LUÍS - LISBOA",
        label: "HOSPITAL DE SÃO LUÍS - LISBOA",
      },
      {
        value: "HOSPITAL DISTRITAL DA FIGUEIRA DA FOZ, E.P.E.",
        label: "HOSPITAL DISTRITAL DA FIGUEIRA DA FOZ, E.P.E.",
      },
      {
        value: "HOSPITAL DISTRITAL DE ÁGUEDA",
        label: "HOSPITAL DISTRITAL DE ÁGUEDA",
      },
      {
        value: "HOSPITAL DISTRITAL DE CHAVES",
        label: "HOSPITAL DISTRITAL DE CHAVES",
      },
      {
        value: "HOSPITAL DISTRITAL DE LAMEGO",
        label: "HOSPITAL DISTRITAL DE LAMEGO",
      },
      {
        value: "HOSPITAL DISTRITAL DE S. JOÃO DA MADEIRA",
        label: "HOSPITAL DISTRITAL DE S. JOÃO DA MADEIRA",
      },
      {
        value: "HOSPITAL DISTRITAL DE SANTARÉM, E.P.E.",
        label: "HOSPITAL DISTRITAL DE SANTARÉM, E.P.E.",
      },
      {
        value: "HOSPITAL DISTRITAL DO FUNDÃO",
        label: "HOSPITAL DISTRITAL DO FUNDÃO",
      },
      {
        value: "HOSPITAL DISTRITAL DO POMBAL",
        label: "HOSPITAL DISTRITAL DO POMBAL",
      },
      {
        value: "HOSPITAL DIVINO ESPIRITO SANTO | PONTA DELGADA",
        label: "HOSPITAL DIVINO ESPIRITO SANTO | PONTA DELGADA",
      },
      {
        value: "HOSPITAL DO ENTRONCAMENTO",
        label: "HOSPITAL DO ENTRONCAMENTO",
      },
      {
        value: "HOSPITAL DO LITORAL ALENTEJANO",
        label: "HOSPITAL DO LITORAL ALENTEJANO",
      },
      {
        value: "HOSPITAL DOS SAMS - LISBOA",
        label: "HOSPITAL DOS SAMS - LISBOA",
      },
      {
        value: "HOSPITAL DOUTOR JOSÉ MARIA GRANDE - PORTALEGRE",
        label: "HOSPITAL DOUTOR JOSÉ MARIA GRANDE - PORTALEGRE",
      },
      {
        value: "HOSPITAL PROF. DOUTOR FERNANDO FONSECA, E.P.E",
        label: "Hospital Prof. Doutor Fernando Fonseca, E.P.E",
      },
      {
        value: "HOSPITAL DR. FRANCISCO ZAGALO-OVAR",
        label: "HOSPITAL DR. FRANCISCO ZAGALO-OVAR",
      },
      {
        value: "HOSPITAL DR. NÉLIO MENDONÇA - FUNCHAL",
        label: "HOSPITAL DR. NÉLIO MENDONÇA - FUNCHAL",
      },
      {
        value: "HOSPITAL ESPÍRITO SANTO-ÉVORA",
        label: "HOSPITAL ESPÍRITO SANTO-ÉVORA",
      },
      {
        value: "HOSPITAL GARCIA DE ORTA, E.P.E.",
        label: "HOSPITAL GARCIA DE ORTA, E.P.E.",
      },
      {
        value: "HOSPITAL GERAL COLÓNIA PORTUGUESA DO BRASIL - C.H.CCOIMBRA",
        label: "HOSPITAL GERAL COLÓNIA PORTUGUESA DO BRASIL - C.H.CCOIMBRA",
      },
      {
        value: "HOSPITAL INFANTE D. PEDRO, E.P.E.",
        label: "HOSPITAL INFANTE D. PEDRO, E.P.E.",
      },
      {
        value: "HOSPITAL INFANTIL SÃO JOÃO DE DEUS - MONTEMOR -O-NOVO",
        label: "HOSPITAL INFANTIL SÃO JOÃO DE DEUS - MONTEMOR -O-NOVO",
      },
      {
        value: "HOSPITAL INTERNACIONAL DOS AÇORES",
        label: "HOSPITAL INTERNACIONAL DOS AÇORES",
      },
      {
        value: "HOSPITAL JOAQUIM URBANO",
        label: "HOSPITAL JOAQUIM URBANO",
      },
      {
        value: "HOSPITAL JOSÉ LUCIANO DE CASTRO - ANADIA",
        label: "HOSPITAL JOSÉ LUCIANO DE CASTRO - ANADIA",
      },
      {
        value: "HOSPITAL LUSÍADAS ALBUFEIRA",
        label: "HOSPITAL LUSÍADAS ALBUFEIRA",
      },
      {
        value: "HOSPITAL LUSÍADAS AMADORA",
        label: "HOSPITAL LUSÍADAS AMADORA",
      },
      {
        value: "HOSPITAL LUSÍADAS LISBOA",
        label: "HOSPITAL LUSÍADAS LISBOA",
      },
      {
        value: "HOSPITAL LUSÍADAS PORTO",
        label: "HOSPITAL LUSÍADAS PORTO",
      },
      {
        value: "HOSPITAL MILITAR PRINCIPAL",
        label: "HOSPITAL MILITAR PRINCIPAL",
      },
      {
        value: "HOSPITAL MILITAR REGIONAL 4",
        label: "HOSPITAL MILITAR REGIONAL 4",
      },
      {
        value: "HOSPITAL MILITAR REGIONAL Nº1",
        label: "HOSPITAL MILITAR REGIONAL Nº1",
      },
      {
        value: "HOSPITAL MILITAR REGIONAL Nº2",
        label: "HOSPITAL MILITAR REGIONAL Nº2",
      },
      {
        value: "HOSPITAL NARCISO FERREIRA",
        label: "HOSPITAL NARCISO FERREIRA",
      },
      {
        value: "HOSPITAL NOSSA SENHORA DA AJUDA-ESPINHO",
        label: "HOSPITAL NOSSA SENHORA DA AJUDA-ESPINHO",
      },
      {
        value: "HOSPITAL NOSSA SENHORA DA ARRÁBIDA",
        label: "HOSPITAL NOSSA SENHORA DA ARRÁBIDA",
      },
      {
        value: "HOSPITAL NOSSA SENHORA DA ASSUNÇÃO - SEIA",
        label: "HOSPITAL NOSSA SENHORA DA ASSUNÇÃO - SEIA",
      },
      {
        value: "HOSPITAL NOSSA SENHORA DA CONCEIÇÃO DE VALONGO",
        label: "HOSPITAL NOSSA SENHORA DA CONCEIÇÃO DE VALONGO",
      },
      {
        value: "HOSPITAL NOSSA SENHORA DA GUIA - AVELAR",
        label: "HOSPITAL NOSSA SENHORA DA GUIA - AVELAR",
      },
      {
        value: "HOSPITAL NOSSA SENHORA DO ROSÁRIO, E.P.E.",
        label: "HOSPITAL NOSSA SENHORA DO ROSÁRIO, E.P.E.",
      },
      {
        value: "HOSPITAL ORDEM DA LAPA",
        label: "HOSPITAL ORDEM DA LAPA",
      },
      {
        value: "HOSPITAL ORDEM DO CARMO - PORTO",
        label: "HOSPITAL ORDEM DO CARMO - PORTO",
      },
      {
        value: "HOSPITAL ORDEM TERCEIRA DA SS TRINDADE PORTO",
        label: "HOSPITAL ORDEM TERCEIRA DA SS TRINDADE PORTO",
      },
      {
        value: "HOSPITAL ORTOPEDICO SANTANA",
        label: "HOSPITAL ORTOPEDICO SANTANA",
      },
      {
        value: "HOSPITAL PARTICULAR DA MADEIRA",
        label: "HOSPITAL PARTICULAR DA MADEIRA",
      },
      {
        value: "HOSPITAL PARTICULAR DE ALMADA",
        label: "HOSPITAL PARTICULAR DE ALMADA",
      },
      {
        value: "HOSPITAL PARTICULAR DE LISBOA",
        label: "HOSPITAL PARTICULAR DE LISBOA",
      },
      {
        value: "HOSPITAL PARTICULAR DO ALGARVE",
        label: "HOSPITAL PARTICULAR DO ALGARVE",
      },
      {
        value: "HOSPITAL PRISIONAL S. JOÃO DE DEUS",
        label: "HOSPITAL PRISIONAL S. JOÃO DE DEUS",
      },
      {
        value: "HOSPITAL PRIVADO DA BOAVISTA - HPP PORTO",
        label: "HOSPITAL PRIVADO DA BOAVISTA - HPP PORTO",
      },
      {
        value: "HOSPITAL PRIVADO DOS CLÉRIGOS - HPP - PORTO",
        label: "HOSPITAL PRIVADO DOS CLÉRIGOS - HPP - PORTO",
      },
      {
        value: "HOSPITAL PULIDO VALENTE, E.P.E.",
        label: "HOSPITAL PULIDO VALENTE, E.P.E.",
      },
      {
        value: "HOSPITAL REYNALDO DOS SANTOS-VILA FRANCA DE XIRA",
        label: "HOSPITAL REYNALDO DOS SANTOS-VILA FRANCA DE XIRA",
      },
      {
        value: "HOSPITAL S. PEDRO PESCADOR",
        label: "HOSPITAL S. PEDRO PESCADOR",
      },
      {
        value: "HOSPITAL SANTA CASA DA MISERICÓRIA DE BENAVENTE",
        label: "HOSPITAL SANTA CASA DA MISERICÓRIA DE BENAVENTE",
      },
      {
        value: "HOSPITAL SANTA CECÍLIA",
        label: "HOSPITAL SANTA CECÍLIA",
      },
      {
        value: "HOSPITAL SANTA LUZIA DE ELVAS",
        label: "HOSPITAL SANTA LUZIA DE ELVAS",
      },
      {
        value: "HOSPITAL SANTA MARIA",
        label: "HOSPITAL SANTA MARIA",
      },
      {
        value: "HOSPITAL SANTA MARIA (PORTO)",
        label: "HOSPITAL SANTA MARIA (PORTO)",
      },
      {
        value: "HOSPITAL SANTA MARIA MAIOR, E.P.E.",
        label: "HOSPITAL SANTA MARIA MAIOR, E.P.E.",
      },
      {
        value: "HOSPITAL SANTA MARTA, E.P.E.",
        label: "HOSPITAL SANTA MARTA, E.P.E.",
      },
      {
        value: "HOSPITAL SANTO ANTÓNIO E.P.E. - PORTO",
        label: "HOSPITAL SANTO ANTÓNIO E.P.E. - PORTO",
      },
      {
        value: "HOSPITAL SANTO ESPIRITO | ILHA TERCEIRA",
        label: "HOSPITAL SANTO ESPIRITO | ILHA TERCEIRA",
      },
      {
        value: "HOSPITAL SÃO MIGUEL - OLIVEIRA DE AZEMEIS",
        label: "HOSPITAL SÃO MIGUEL - OLIVEIRA DE AZEMEIS",
      },
      {
        value: "HOSPITAL SÃO PEDRO GONÇALVES TELMO-PENICHE",
        label: "HOSPITAL SÃO PEDRO GONÇALVES TELMO-PENICHE",
      },
      {
        value: "HOSPITAL SÃO SEBASTIÃO, E.P.E.",
        label: "HOSPITAL SÃO SEBASTIÃO, E.P.E.",
      },
      {
        value: "HOSPITAL SÃO TEOTÓNIO, E.P.E.",
        label: "HOSPITAL SÃO TEOTÓNIO, E.P.E.",
      },
      {
        value: "HOSPITAL SENHORA DA OLIVEIRA - GUIMARÃES",
        label: "HOSPITAL SENHORA DA OLIVEIRA - GUIMARÃES",
      },
      {
        value: "HOSPITAL SOUSA MARTINS - GUARDA",
        label: "HOSPITAL SOUSA MARTINS - GUARDA",
      },
      {
        value: "HOSPITAL VALENTIM RIBEIRO",
        label: "HOSPITAL VALENTIM RIBEIRO",
      },
      {
        value: "HOSPITAL VENERÁVEL IRMANDADE N.S. DA LAPA - PORTO",
        label: "HOSPITAL VENERÁVEL IRMANDADE N.S. DA LAPA - PORTO",
      },
      {
        value: "HOSPITAL VENERÁVEL IRMANDADE N.S. DO TERÇO DE CARIDADE - PORTO",
        label: "HOSPITAL VENERÁVEL IRMANDADE N.S. DO TERÇO DE CARIDADE - PORTO",
      },
      {
        value: "HOSPITAL VISCONDE DE SALREU-ESTARREJA",
        label: "HOSPITAL VISCONDE DE SALREU-ESTARREJA",
      },
      {
        value: "HOSPITAL VILA FRANCA DE XIRA",
        label: "HOSPITAL VILA FRANCA DE XIRA",
      },
      {
        value: "INSTITUTO CUF DE ONCOLOGIA - CLUSTER TEJO",
        label: "INSTITUTO CUF DE ONCOLOGIA - CLUSTER TEJO",
      },
      {
        value: "INSTITUTO PORTUGUÊS DE ONCOLOGIA COIMBRA",
        label: "INSTITUTO PORTUGUÊS DE ONCOLOGIA COIMBRA",
      },
      {
        value: "INSTITUTO PORTUGUÊS DE ONCOLOGIA LISBOA",
        label: "INSTITUTO PORTUGUÊS DE ONCOLOGIA LISBOA",
      },
      {
        value: "INSTITUTO PORTUGUÊS DE ONCOLOGIA PORTO",
        label: "INSTITUTO PORTUGUÊS DE ONCOLOGIA PORTO",
      },
      {
        value: "INSTITUTO PORTUGUÊS DE RITMO CARDÍACO",
        label: "INSTITUTO PORTUGUÊS DE RITMO CARDÍACO",
      },
      { value: "IPATIMUP", label: "IPATIMUP" },
      { value: "IPL / ESTeSL", label: "IPL / ESTeSL" },
      { value: "IMP DIAGNOSTICS", label: "IMP DIAGNOSTICS" },
      {
        value: "JOAQUIM CHAVES SAÚDE - CASA DE SAÚDE DE CARNAXIDE",
        label: "JOAQUIM CHAVES SAÚDE - CASA DE SAÚDE DE CARNAXIDE",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CENTRO ONCOLÓGICO DRA. NATÁLIA CHAVES",
        label: "JOAQUIM CHAVES SAÚDE - CENTRO ONCOLÓGICO DRA. NATÁLIA CHAVES",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLINICA DE CARCAVELOS",
        label: "JOAQUIM CHAVES SAÚDE - CLINICA DE CARCAVELOS",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE CASCAIS",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE CASCAIS",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE ENTRECAMPOS",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE ENTRECAMPOS",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE FARO",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE FARO",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE GASTROENTEROLOGIA DE FARO",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE GASTROENTEROLOGIA DE FARO",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE MIRAFLORES",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE MIRAFLORES",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE OEIRAS",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE OEIRAS",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE OLHÃO",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE OLHÃO",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIOLOGIA DE CASCAIS",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIOLOGIA DE CASCAIS",
      },
      {
        value:
          "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIOLOGIA DE VILA FRANCA DE XIRA",
        label:
          "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIOLOGIA DE VILA FRANCA DE XIRA",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DA MADEIRA",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DA MADEIRA",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DE SANTARÉM",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DE SANTARÉM",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DO ALGARVE",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DO ALGARVE",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DO PORTO",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DO PORTO",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE SINTRA",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE SINTRA",
      },
      {
        value:
          "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANÁLISE",
        label:
          "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANÁLISES CLÍNICAS",
      },
      {
        value:
          "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANÁLISE",
        label:
          "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANÁLISES CLÍNICAS ALGARVE",
      },
      {
        value:
          "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANATOMIA PATOLÓGICA",
        label:
          "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANATOMIA PATOLÓGICA",
      },
      {
        value: "JOSE MELLO SAÚDE",
        label: "JOSE MELLO SAÚDE",
      },
      {
        value: "JÚLIO TEIXEIRA, SA",
        label: "JÚLIO TEIXEIRA, SA",
      },
      { value: "LENICARE", label: "LENICARE" },
      {
        value: "LENITUDES MEDICAL CENTER",
        label: "LENITUDES MEDICAL CENTER",
      },
      {
        value: "LISBON UNITED KINGDOM HOSPITAL, SERVIÇOS DE SAÚDE, S.ª",
        label: "LISBON UNITED KINGDOM HOSPITAL, SERVIÇOS DE SAÚDE, S.ª",
      },
      {
        value: "MATERNIDADE DR. ALFREDO DA COSTA",
        label: "MATERNIDADE DR. ALFREDO DA COSTA",
      },
      {
        value: "MATERNIDADE DR. DANIEL DE MATOS",
        label: "MATERNIDADE DR. DANIEL DE MATOS",
      },
      { value: "NEPHROCARE", label: "NEPHROCARE" },
      {
        value: "OFFICIAL AGENCY",
        label: "OFFICIAL AGENCY",
      },
      { value: "Reino Unido", label: "Reino Unido" },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE ALMEIRIM",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE ALMEIRIM",
      },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE AVINTES",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE AVINTES",
      },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE ESTREMOZ",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE ESTREMOZ",
      },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE MORA",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE MORA",
      },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS FAMALICÃO",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS FAMALICÃO",
      },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS JUIZ DE FORA",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS JUIZ DE FORA",
      },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS PORTIMÃO",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS PORTIMÃO",
      },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS RAINHA DONA LEONOR",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS RAINHA DONA LEONOR",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR AGUDA",
        label: "UNIDADE DE SAÚDE FAMILIAR AGUDA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR ALVES MARTINS",
        label: "UNIDADE DE SAÚDE FAMILIAR ALVES MARTINS",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR BRIOSA",
        label: "UNIDADE DE SAÚDE FAMILIAR BRIOSA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR CELAS SAÚDE",
        label: "UNIDADE DE SAÚDE FAMILIAR CELAS SAÚDE",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR COVA DA PIEDADE",
        label: "UNIDADE DE SAÚDE FAMILIAR COVA DA PIEDADE",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR CSI SEIXAL",
        label: "UNIDADE DE SAÚDE FAMILIAR CSI SEIXAL",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR DE SANTIAGO-LEIRIA",
        label: "UNIDADE DE SAÚDE FAMILIAR DE SANTIAGO-LEIRIA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR DE TORNADA",
        label: "UNIDADE DE SAÚDE FAMILIAR DE TORNADA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR DE VALBOM",
        label: "UNIDADE DE SAÚDE FAMILIAR DE VALBOM",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR DE VALONGO",
        label: "UNIDADE DE SAÚDE FAMILIAR DE VALONGO",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR DO MAR",
        label: "UNIDADE DE SAÚDE FAMILIAR DO MAR",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR LAVRADIO",
        label: "UNIDADE DE SAÚDE FAMILIAR LAVRADIO",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR LUSITÂNIA",
        label: "UNIDADE DE SAÚDE FAMILIAR LUSITÂNIA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR MACTAMÃ",
        label: "UNIDADE DE SAÚDE FAMILIAR MACTAMÃ",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR RAMADA",
        label: "UNIDADE DE SAÚDE FAMILIAR RAMADA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR SANTO ANTÓNIO DA CHARNECA",
        label: "UNIDADE DE SAÚDE FAMILIAR SANTO ANTÓNIO DA CHARNECA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR SÃO GONÇALO",
        label: "UNIDADE DE SAÚDE FAMILIAR SÃO GONÇALO",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR SENHORA DA LAPA",
        label: "UNIDADE DE SAÚDE FAMILIAR SENHORA DA LAPA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR TERRAS AZURARA",
        label: "UNIDADE DE SAÚDE FAMILIAR TERRAS AZURARA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR VALE DO VEZ",
        label: "UNIDADE DE SAÚDE FAMILIAR VALE DO VEZ",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR VILLA LONGA",
        label: "UNIDADE DE SAÚDE FAMILIAR VILLA LONGA",
      },
      {
        value: "UNIDADE DE SAÚDE PÚBLICA DE ÉVORA",
        label: "UNIDADE DE SAÚDE PÚBLICA DE ÉVORA",
      },
      {
        value: "UNIDADE LOCAL DE SAÚDE DA GUARDA, EPE",
        label: "UNIDADE LOCAL DE SAÚDE DA GUARDA, EPE",
      },
      {
        value: "UNIDADE LOCAL DE SAÚDE DE MATOSINHOS, S.A",
        label: "UNIDADE LOCAL DE SAÚDE DE MATOSINHOS, S.A",
      },
      {
        value: "UNIDADE LOCAL DE SAÚDE DO ALTO MINHO",
        label: "UNIDADE LOCAL DE SAÚDE DO ALTO MINHO",
      },
      {
        value: "UNIDADE LOCAL DE SAÚDE DO BAIXO ALENTEJO",
        label: "UNIDADE LOCAL DE SAÚDE DO BAIXO ALENTEJO",
      },
      {
        value: "UNIDADE LOCAL DE SAÚDE DO LITORAL ALENTEJANO",
        label: "UNIDADE LOCAL DE SAÚDE DO LITORAL ALENTEJANO",
      },
      {
        value: "UNIDADE LOCAL DE SAÚDE DO NORDESTE, E.P.E.",
        label: "UNIDADE LOCAL DE SAÚDE DO NORDESTE, E.P.E.",
      },
      {
        value: "UNIDADE LOCAL DE SAÚDE DO NORTE ALENTEJANO",
        label: "UNIDADE LOCAL DE SAÚDE DO NORTE ALENTEJANO",
      },
      {
        value: "Unilabs - LAP PORTO",
        label: "Unilabs - LAP Porto",
      },
      { value: "Synlab Porto", label: "Synlab Porto" },
      { value: "Outro", label: "Outro" },
    ];

    inst.map((i) => {
      i.label = setCapitalize(i.label);
    });

    return inst;
  });

  userDrawer = props.user;

  const prop = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  const handleUpload = () => {
    const formData = new FormData();

    formData.append("file", fileList[0]);
    formData.append("user_id", userDrawer.id);

    setUploading(true);
    // You can use any AJAX library you like
    fetch("https://api.spap.pt/api/admin/upload-avatar", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            userDrawer.avatar = data.url;
            session_storage.all_data.users.filter((user) => {
              if (user.id == user_id) {
                user.avatar = data.url;
              }
            });
            localStorage.setItem("session_data", JSON.stringify(storage));
          })
          .catch((err) => {});
      })
      .then(() => {
        setFileList([]);
        message.success("upload successfully.");
      })
      .catch(() => {
        message.error("upload failed.");
      })
      .finally(() => {
        setUploading(false);
      });
  };

  return (
    <>
      <Row align="middle" gutter={[8, 8]}>
        <Modal
          open={isModalOpen}
          width={300}
          onCancel={hideModal}
          footer={[
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={fileList.length === 0}
              loading={uploading}
              style={{
                borderRadius: "12px",
                background: "#494D7D",
                width: "100%",
                height: "45px",
              }}
            >
              {uploading ? "Uploading" : "Start Upload"}
            </Button>,
          ]}
        >
          <h5
            style={{
              marginBottom: 0,
              fontSize: "18px",
              textAlign: "center",
              paddingTop: "20px",
              paddingBottom: "10px",
            }}
          >
            Faça upload da imagem do sócio
          </h5>
          <p
            style={{
              marginBottom: 0,
              fontSize: "14px",
              textAlign: "center",
              color: "#809FB8",
              paddingBottom: "20px",
            }}
          >
            Tamanho máximo de upload (2 MB)
          </p>
          <Dragger {...prop} style={{ borderRadius: "12px" }}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p
              className="ant-upload-text"
              style={{ padding: "0px 10px", fontSize: "14px" }}
            >
              Clique ou arraste para esta área a imagem de perfil
            </p>
          </Dragger>
        </Modal>
        <Col span={12}>
          <Row align="middle">
            <div
              style={{
                borderRadius: "80px",
                border: "1px solid #494D7D",
                width: "100px",
                height: "100px",
              }}
            >
              <div onClick={showModal}>
                <img
                  src={
                    userDrawer.avatar != null
                      ? "https://api.spap.pt/avatars/" + userDrawer.avatar
                      : "https://api.spap.pt/avatars/socio-default-1.svg"
                  }
                  style={{
                    width: "98px",
                    height: "98px",
                    borderRadius: "50px",
                  }}
                />
              </div>
            </div>
            <h6 style={{ color: "#809FB8", paddingLeft: "10px" }}>
              {userDrawer.name}{" "}
              {userDrawer.apelido != null && userDrawer.apelido != ""
                ? userDrawer.apelido
                : ""}
            </h6>
          </Row>
        </Col>
        <Col span={6}>
          <h6 style={styles.h6_label}>Tipo de sócio</h6>
          <Select
            key={"tipo-socio" + userDrawer.id}
            name="tipo_socio"
            className="tipo-socio-select"
            defaultValue={userDrawer.tipo_socio ? userDrawer.tipo_socio : ""}
            style={{ height: "45px" }}
            options={[
              { value: "", label: "Não definido" },
              { value: "Titular", label: "Titular" },
              { value: "Honorário", label: "Honorário" },
            ]}
          />
        </Col>
        <Col span={6}>
          <h6 style={styles.h6_label}>Nº. de Sócio</h6>
          <Input
            defaultValue={
              userDrawer.numero_socio ? userDrawer.numero_socio : ""
            }
            key={"numero-socio-" + userDrawer.id}
            name="numero_socio"
            className="input-socio"
            style={{
              borderRadius: "6px",
              color: "#809FB8",
              padding: "0px 5px",
              height: "45px",
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "16px",
              border: "1px solid rgb(128, 159, 184) !important",
            }}
          />
        </Col>
      </Row>
      <Divider style={{ margin: "15px 0" }} />
      <Row align={"middle"}>
        <Col span={20}>
          <span style={{ paddingRight: "15px" }}>
            Caso pretenda surgir também como patologista para{" "}
            <u style={{ color: "#0C1151", fontWeight: "bold" }}>
              pedido de 2ª opinião
            </u>
            , selecione a seguinte opção:
          </span>{" "}
        </Col>
        <Col span={4} align={"end"}>
          <Switch
            id="mostra_segunda_opiniao"
            key={"segunda_opiniao-" + userDrawer.id}
            defaultChecked={!!userDrawer.segunda_opiniao}
          />{" "}
        </Col>
      </Row>
      <Row style={{ padding: "6px 0" }} align="bottom" gutter={[8, 8]}>
        <Col span={12}>
          <h6 style={styles.h6_label}>Primeiro nome</h6>
          <Input
            key={"name-" + userDrawer.id}
            name="name"
            placeholder="Primeiro nome"
            style={styles.input_class}
            defaultValue={userDrawer.name}
          />
        </Col>
        <Col span={12}>
          <h6 style={styles.h6_label}>Apelido</h6>
          <Input
            key={"apelido-" + userDrawer.id}
            name="apelido"
            placeholder="Apelido"
            style={styles.input_class}
            defaultValue={userDrawer.apelido ? userDrawer.apelido : ""}
          />
        </Col>
      </Row>
      <Divider style={{ margin: "15px 0" }} />
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Col span={8}>
          <h5 style={{ marginBottom: "10px", fontSize: "18px" }}>
            Apresentação
          </h5>
        </Col>
        <Col span={16} style={{ textAlign: "right" }}>
          <span
            style={{
              paddingRight: "15px",
              color: "#809FB8",
              fontWeight: "bold",
            }}
          >
            Visivel para:{" "}
          </span>{" "}
          <Switch
            id="mostra_apresentacao_socios"
            key={"mostra_apresentacao_socios-" + userDrawer.id}
            defaultChecked={!!userDrawer.mostra_apresentacao_socios}
          />{" "}
          <span style={{ color: "#494D7D" }}></span>Sócios SPAP{" "}
          <Switch
            id="mostra_apresentacao_publico"
            key={"mostra_apresentacao_publico" + userDrawer.id}
            defaultChecked={!!userDrawer.mostra_apresentacao_publico}
            style={{ marginLeft: "10px" }}
          />{" "}
          <span style={{ color: "#494D7D" }}></span>Público geral
        </Col>
      </Row>
      <Row style={{ padding: "6px 0" }} gutter={[8, 8]}>
        <Col span={12}>
          <h6 style={styles.h6_label}>Instituição</h6>
          <Select
            key={"instituicao-1-" + userDrawer.id}
            style={styles.select_class}
            className="instituicao-select"
            placeholder="Instituição"
            defaultValue={userDrawer.instituicao ? userDrawer.instituicao : ""}
            showSearch
            options={instituicaoOptions}
          />
        </Col>
        <Col span={12}>
          <h6 style={styles.h6_label}>Outra Instituição</h6>
          <Input
            key={"instituicao-2-" + userDrawer.id}
            name="instituicao_2"
            placeholder="Outra Instituição"
            style={styles.input_class2}
            defaultValue={
              userDrawer.instituicao_2 ? userDrawer.instituicao_2 : ""
            }
          />
        </Col>
      </Row>
      <Row style={{ padding: "6px 0" }} gutter={[8, 8]}>
        <Col span={12}>
          <h6 style={styles.h6_label}>Área de Diferenciação</h6>
          <Select
            key={"area-" + userDrawer.id}
            defaultValue={
              userDrawer.area_subespecializacao
                ? userDrawer.area_subespecializacao
                : []
            }
            style={styles.select_class}
            className="area-select"
            placeholder="Área de Diferenciação"
            name="area"
            showSearch
            options={[
              { value: "Patologia geral", label: "Patologia geral" },
              { value: "Citologia", label: "Citologia" },
              { value: "Patologia cutânea", label: "Patologia cutânea" },
              {
                value: "Patologia da cabeça e pescoço",
                label: "Patologia da cabeça e pescoço",
              },
              {
                value: "Patologia das partes moles",
                label: "Patologia das partes moles",
              },
              {
                value: "Patologia do sistema nervosa",
                label: "Patologia do sistema nervoso",
              },
              { value: "Patologia digital", label: "Patologia digital" },
              { value: "Patologia endócrina", label: "Patologia endócrina" },
              {
                value: "Patologia fetoplacentar",
                label: "Patologia fetoplacentar",
              },
              {
                value: "Patologia gastrointestinal",
                label: "Patologia gastrointestinal",
              },
              {
                value: "Patologia ginecológica",
                label: "Patologia ginecológica",
              },
              {
                value: "Patologia hematolinfoide",
                label: "Patologia hematolinfoide",
              },
              {
                value: "Patologia hepatobiliar",
                label: "Patologia hepatobiliar",
              },
              { value: "Patologia mamária", label: "Patologia mamária" },
              { value: "Patologia molecular", label: "Patologia molecular" },
              {
                value: "Patologia osteoarticular",
                label: "Patologia osteoarticular",
              },
              {
                value: "Patologia pancreática",
                label: "Patologia pancreática",
              },
              { value: "Patologia pulmonar", label: "Patologia pulmonar" },
              { value: "Patologia urogenital", label: "Patologia urogenital" },
            ]}
          />
        </Col>

        <Col span={12}>
          <h6 style={styles.h6_label}>Outra Área de Diferenciação</h6>
          <Select
            key={"area-2-" + userDrawer.id}
            placeholder="Outra área de diferenciação"
            defaultValue={
              userDrawer.area_subespecializacao_2
                ? userDrawer.area_subespecializacao_2
                : []
            }
            style={styles.select_class}
            className="area2-select"
            name="area_2"
            showSearch
            options={[
              { value: "Patologia geral", label: "Patologia geral" },
              { value: "Citologia", label: "Citologia" },
              { value: "Patologia cutânea", label: "Patologia cutânea" },
              {
                value: "Patologia da cabeça e pescoço",
                label: "Patologia da cabeça e pescoço",
              },
              {
                value: "Patologia das partes moles",
                label: "Patologia das partes moles",
              },
              {
                value: "Patologia do sistema nervosa",
                label: "Patologia do sistema nervoso",
              },
              { value: "Patologia digital", label: "Patologia digital" },
              { value: "Patologia endócrina", label: "Patologia endócrina" },
              {
                value: "Patologia fetoplacentar",
                label: "Patologia fetoplacentar",
              },
              {
                value: "Patologia gastrointestinal",
                label: "Patologia gastrointestinal",
              },
              {
                value: "Patologia ginecológica",
                label: "Patologia ginecológica",
              },
              {
                value: "Patologia hematolinfoide",
                label: "Patologia hematolinfoide",
              },
              {
                value: "Patologia hepatobiliar",
                label: "Patologia hepatobiliar",
              },
              { value: "Patologia mamária", label: "Patologia mamária" },
              { value: "Patologia molecular", label: "Patologia molecular" },
              {
                value: "Patologia osteoarticular",
                label: "Patologia osteoarticular",
              },
              {
                value: "Patologia pancreática",
                label: "Patologia pancreática",
              },
              { value: "Patologia pulmonar", label: "Patologia pulmonar" },
              { value: "Patologia urogenital", label: "Patologia urogenital" },
            ]}
          />{" "}
        </Col>
      </Row>
      <Row style={{ padding: "6px 0" }} gutter={[8, 8]}>
        <Col span={24}>
          <h6 style={styles.h6_label}>3ª. Área de Diferenciação (opcional)</h6>
          <Input
            key={"area3-" + userDrawer.id}
            name="area_3"
            placeholder="3ª. Área de Diferenciação"
            style={styles.input_class2}
            defaultValue={
              userDrawer.area_diferenciacao_3
                ? userDrawer.area_diferenciacao_3
                : ""
            }
          />
        </Col>
      </Row>

      <Row style={{ padding: "6px 0" }} gutter={[8, 8]}>
        <Col span={12}>
          <h6 style={styles.h6_label}>Grau</h6>
          <Select
            key={"Grau-" + userDrawer.id}
            defaultValue={userDrawer.grau ? userDrawer.grau : []}
            style={styles.select_class}
            className="grau-select"
            placeholder="Grau"
            name="grau"
            showSearch
            options={[
              { value: "Especialista", label: "Especialista" },
              { value: "Interno", label: "Interno" },
            ]}
          />
        </Col>

        <Col span={12}>
          <h6 style={styles.h6_label}>Outro grau</h6>
          <Input
            key={"grau2-" + userDrawer.id}
            name="grau_2"
            placeholder="Outro grau"
            style={styles.input_class2}
            defaultValue={userDrawer.grau_2 ? userDrawer.grau_2 : ""}
          />
        </Col>
      </Row>
      <Divider style={{ margin: "15px 0" }} />
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Col span={8}>
          <h5 style={{ marginBottom: "10px", fontSize: "18px" }}>
            Dados de contacto
          </h5>
        </Col>
        <Col span={16} style={{ textAlign: "right" }}>
          <span
            style={{
              paddingRight: "15px",
              color: "#809FB8",
              fontWeight: "bold",
            }}
          >
            Visível:
          </span>{" "}
          <Switch
            id="mostra_dados_socios"
            key={"mostra_dados_socios" + userDrawer.id}
            defaultChecked={!!userDrawer.mostra_dados_socios}
          />{" "}
          <span style={{ color: "#494D7D" }}></span>E-mail{" "}
          <Switch
            id="mostra_dados_publico"
            style={{ marginLeft: "10px" }}
            key={"mostra_dados_publico" + userDrawer.id}
            defaultChecked={!!userDrawer.mostra_dados_publico}
          />{" "}
          <span style={{ color: "#494D7D" }}></span>Telefone
        </Col>
      </Row>
      <Row style={{ padding: "6px 0" }} align="bottom" gutter={[8, 8]}>
        <Col span={12}>
          <h6 style={styles.h6_label}>Email</h6>
          <Input
            key={"email-" + userDrawer.id}
            name="email"
            placeholder="Email"
            style={styles.input_class}
            defaultValue={userDrawer.email}
            disabled
          />
        </Col>
        <Col span={12}>
          <h6 style={styles.h6_label}>Telefone</h6>
          <Input
            key={"telefone-" + userDrawer.id}
            name="telefone"
            placeholder="Telefone"
            style={styles.input_class}
            defaultValue={userDrawer.telefone ? userDrawer.telefone : ""}
          />
        </Col>
      </Row>
      <Divider style={{ margin: "15px 0" }} />
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Col span={8}>
          <h5 style={{ marginBottom: "10px", fontSize: "18px" }}>
            Resumo Profissional
          </h5>
        </Col>
        <Col span={16} style={{ textAlign: "right" }}>
          <span
            style={{
              paddingRight: "15px",
              color: "#809FB8",
              fontWeight: "bold",
            }}
          >
            Visível para:
          </span>{" "}
          <Switch
            id="mostra_resumo_socios"
            key={"mostra_resumo_socios" + userDrawer.id}
            defaultChecked={!!userDrawer.mostra_resumo_socios}
          />{" "}
          <span style={{ color: "#494D7D" }}></span>Sócios SPAP{" "}
          <Switch
            id="mostra_resumo_publico"
            key={"mostra_resumo_publico" + userDrawer.id}
            style={{ marginLeft: "10px" }}
            defaultChecked={!!userDrawer.mostra_resumo_publico}
          />{" "}
          <span style={{ color: "#494D7D" }}></span>Público geral
        </Col>
      </Row>
      <Row style={{ padding: "6px 0" }} align="bottom" gutter={[8, 8]}>
        <TextArea
          name="resumo_profissional"
          style={{
            color: "#809FB8",
            fontStyle: "italic",
            border: "2px solid #D9E1E7",
            borderRadius: "16px",
          }}
          placeholder="Escreva aqui a sua mensagem..."
          autoSize={{ minRows: 3 }}
          defaultValue={
            userDrawer.resumo_profissional ? userDrawer.resumo_profissional : ""
          }
          key={"resumo-" + userDrawer.id}
        />
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <h6 style={styles.h6_label}>ORCID</h6>
          <Input
            key={"orcid-" + userDrawer.id}
            name="orcid"
            placeholder="ORCID"
            style={styles.input_class}
            defaultValue={userDrawer.orcid ? userDrawer.orcid : ""}
          />
        </Col>
      </Row>
      <Divider style={{ margin: "15px 0" }} />
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Col span={8}>
          <h5 style={{ marginBottom: "10px", fontSize: "18px" }}>
            Sócios titulares proponentes
          </h5>
        </Col>
        <Col span={16} style={{ textAlign: "right" }}>
          <span
            style={{
              paddingRight: "15px",
              color: "#809FB8",
              fontWeight: "bold",
            }}
          >
            Visível para:
          </span>{" "}
          <Switch
            id="mostra_dados_profissionais_socios"
            key={"mostra_dados_profissionais_socios" + userDrawer.id}
            defaultChecked={!!userDrawer.mostra_dados_profissionais_socios}
          />{" "}
          <span style={{ color: "#494D7D" }}></span>Sócios SPAP{" "}
          <Switch
            id="mostra_dados_profissionais_publico"
            key={"mostra_dados_profissionais_publico" + userDrawer.id}
            defaultChecked={!!userDrawer.mostra_dados_profissionais_publico}
            style={{ marginLeft: "10px" }}
          />{" "}
          <span style={{ color: "#494D7D" }}></span>Público geral
        </Col>
      </Row>

      <Row style={{ padding: "6px 0" }} align="bottom" gutter={[8, 8]}>
        <Col span={24}>
          <h6 style={styles.h6_label}>Sócio titular proponente</h6>
          <Input
            key={"sociotitular-" + userDrawer.id}
            name="socio_titular"
            placeholder="Sócio Titular"
            style={styles.input_class}
            defaultValue={userDrawer.socio_titular}
          />
        </Col>
      </Row>
      <Divider style={{ margin: "15px 0" }} />
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Col span={8}>
          <h5 style={{ marginBottom: "10px", fontSize: "18px" }}>
            Links de páginas web próprias
          </h5>
        </Col>
        <Col span={16} style={{ textAlign: "right" }}>
          <span
            style={{
              paddingRight: "15px",
              color: "#809FB8",
              fontWeight: "bold",
            }}
          >
            Visível para:
          </span>{" "}
          <Switch
            id="mostra_links_socios"
            key={"mostra_links_socios" + userDrawer.id}
            defaultChecked={!!userDrawer.mostra_links_socios}
          />{" "}
          <span style={{ color: "#494D7D" }}></span>Sócios SPAP{" "}
          <Switch
            id="mostra_links_publico"
            key={"mostra_links_publico" + userDrawer.id}
            style={{ marginLeft: "10px" }}
            defaultChecked={!!userDrawer.mostra_links_publico}
          />{" "}
          <span style={{ color: "#494D7D" }}></span>Público geral
        </Col>
      </Row>
      <Row style={{ padding: "6px 0" }} align="bottom" gutter={[8, 8]}>
        <Col span={12}>
          <h6 style={styles.h6_label}>Página web #1</h6>
          <Input
            key={"pagina_web_1-" + userDrawer.id}
            name="pagina_web_1"
            placeholder="Página web #1"
            style={styles.input_class}
            defaultValue={
              userDrawer.pagina_web_1 ? userDrawer.pagina_web_1 : ""
            }
          />
        </Col>
        <Col span={12}>
          <h6 style={styles.h6_label}>Página web #2</h6>
          <Input
            key={"pagina_web_2-" + userDrawer.id}
            name="pagina_web_2"
            placeholder="Página web #2"
            style={styles.input_class}
            defaultValue={
              userDrawer.pagina_web_2 ? userDrawer.pagina_web_2 : ""
            }
          />
        </Col>
      </Row>
    </>
  );
}
