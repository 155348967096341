import styled from 'styled-components'

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1.042vw;
    gap: ${props => props.gap};
    place-self: ${props => props.placeself};

    a {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    @media (min-width: 992px) {
        width: ${props => props.width};
        min-width: ${props => props.width};
    }

    @media (max-width: 992px) {
        gap: 20px;
        margin-bottom: 10px;
        margin-top: 10px;

        ${props => props.mobile && `
            flex-direction: ${props.mobile}
        `}
    }
`

export default function FlexInRow( props ) {
    return (
        <Wrapper
            id={props.id}
            className={props.className}
            gap={props.gap}
            placeself={props.placeself}
            width={props.width}
            mobile={props.mobile}
        >
            {props.children}
        </Wrapper>
    )
}