import { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import {
  notification,
  Tour,
  Button,
  message,
  Space,
  Input,
  Radio,
  Modal,
  Table,
  Upload,
  Select,
  Drawer,
  Row,
  Col,
  Divider,
  Form,
  Tooltip,
} from "antd";
import {
  CheckOutlined,
  InboxOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { usePapaParse } from "react-papaparse";
import dayjs from "dayjs";
import Sidebar from "../../../Components/Backoffice/Sidebar";
import Card from "../../../Components/Backoffice/Card";
import Title from "../../../Components/Backoffice/Title";
import Header from "../../../Components/Backoffice/Header";
import Footer from "../../../Components/Backoffice/Footer";
import DrawerPerfil from "../../../Pages/Backoffice/Socios/drawerPerfil";
import { ReactComponent as IconSearch } from "../../../Assets/Images/Backoffice/Search.svg";
import { ReactComponent as IconOptions } from "../../../Assets/Images/Backoffice/Options.svg";
import { ReactComponent as IconUpload } from "../../../Assets/Images/Backoffice/Upload-off.svg";

import axios from "axios";
import CardQuotes from "../../../Components/Backoffice/CardQuotes";

const { TextArea } = Input;
const { Dragger } = Upload;

const Wrapper = styled.main`
  background-color: #e8e9f3;
  display: grid;
  grid-template-columns: 7.083vw 1fr;
  min-height: 100vh;

  @media (max-width: 992px) {
  }

  // .ant-select-selector {
  //   min-height: 46px !important;
  //   border: 2px solid rgb(217, 225, 231) !important;
  //   border-radius: 16px !important;
  //   color: rgb(134, 136, 168);
  //   font-size: 16px;
  // }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 44px !important;
  }

  .ant-form-item {
    margin-bottom: 12px !important;
  }

  .table-success,
  .table-success:hover,
  .table-success:hover td {
    background-color: #ddffdd !important;
  }

  .table-danger,
  .table-danger:hover,
  .table-danger:hover td {
    background: #ffdddd !important;
  }

  .table-warning,
  .table-warning:hover,
  .table-warning:hover td {
    background: #ffffcc !important;
  }
`;

const Content = styled.div`
  padding: 1.771vw;
`;

const CardHeader = styled.div`
  align-items: center;
  display: grid;
  gap: 60px;
  grid-template-columns: 1fr auto auto;
`;

const Search = styled.div`
  position: relative;

  input {
    background-color: #f1f5f700;
    border: 2px solid #d9e1e7;
    border-radius: 16px;
    color: #8688a8;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0px;
    line-height: 18px;
    margin: 0;
    padding: 12px 58px 12px 26px;
    outline: 0;
  }

  .icon {
    position: absolute;
    right: 26px;
    top: 8px;
  }
`;

const CardContent = styled.div`
  margin: 40px 0;
  position: relative;

  table th {
    color: #809fb8 !important;
  }

  .ant-checkbox-checked span.ant-checkbox-inner,
  .ant-checkbox-checked:hover span.ant-checkbox-inner {
    background-color: #1ad598 !important;
    border-color: #1ad598 !important;
  }

  .ant-checkbox:hover span.ant-checkbox-inner,
  .ant-checkbox-wrapper.item__checkbox:hover .ant-checkbox-inner {
    border-color: #1ad598 !important;
  }

  .user-name {
    color: #494d7d;
  }

  .icon-upload {
    cursor: pointer;
    height: 46px;
    width: 46px;
  }

  .user-number {
    border-color: #d9e1e7cc;
    color: #809fb8;
    text-align: center;
  }

  .user-active {
    background-color: #1ad5984d;
    border: none;
    color: #1ad598;
  }

  .user-desactive {
    background-color: #ea3a3d4d;
    border: none;
    color: #ea3a3d;
  }

  .user-disabled {
    background-color: #d9e1e7;
    border: none;
    color: #99b2c6;
  }

  li.ant-pagination-item {
    border: 1px solid #d9e1e7cc;
  }

  li.ant-pagination-item.ant-pagination-item-active {
    background-color: #8688a8;
    border-color: #8688a8;
    color: #ffffff;
  }
`;

const TableFooter = styled.div`
  bottom: 16px;
  left: 0;
  position: absolute;
`;

const RadioGroup = styled.div`
  button {
    background-color: #494d7d;
    border: none;
    color: #ffffff;
    transition: 0.3s ease;
  }

  button:hover {
    background-color: #1ad598;
    color: #ffffff !important;
  }
`;

const UploadButtonStyle = styled.button`
  background-color: #494d7d;
  border: none;
  color: #ffffff;
  transition: 0.3s ease;
  font-size: 14px;
  height: 32px;
  padding: 4px 15px;
  border-radius: 6px;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);

  :hover {
    background-color: #1ad598;
    color: #ffffff !important;
  }
`;

var styles = {
  h6_label: {
    color: "#809FB8",
    fontFamily: "Roboto",
    fontSize: "15px",
    marginTop: "1%",
  },
  input_class_disabled: {
    marginBottom: "2%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    opacity: 0.6,
    pointerEvents: "none",
  },
  input_class: {
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    width: "100%",
  },
  date_class: {
    borderRadius: "6px",
    padding: "0 10px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    width: "100%",
  },
  select_class: {
    height: "45px",
    color: "#0C1151",
    width: "100%",
    border: "2px solid rgb(217, 225, 231)",
    borderRadius: "16px",
    height: "46px",
  },
  textarea_class: {
    borderRadius: "6px",
    border: "1px solid #809FB8",
    color: "#0C1151",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
};

export default function Quotas(props) {
  document.title = "SPAP - Quotas";
  const { readString } = usePapaParse();
  const [form] = Form.useForm();

  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSaveQuotasModalOpen, setIsSaveQuotasModalOpen] = useState(false);
  const [quotaDataToEdit, setQuotaDataToEdit] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [dataDrawer, setDataDrawer] = useState({});
  const [quotasBkp, setListaQuotasBkp] = useState([]);
  const [dataBkp, setDataBkp] = useState([]);
  const [open, setOpen] = useState(false);
  const [dataToShow, setDataToShow] = useState([]);
  const [listaQuotas, setListaQuotas] = useState([]);
  const [fileRecibo, setfileRecibo] = useState([]);
  const [fileComprovativo, setfileComprovativo] = useState([]);
  const [fileNotaCredito, setfileNotaCredito] = useState([]);
  const [years, setYears] = useState([]);

  const propReciboPDF = {
    onRemove: (file) => {
      const index = fileRecibo.indexOf(file);
      const newFileList = fileRecibo.slice();
      newFileList.splice(index, 1);
      setfileRecibo(newFileList);
    },
    beforeUpload: (file) => {
      const isPDF = file.type === "application/pdf";
      if (!isPDF) {
        message.error(`${file.name} não é um ficheiro PDF`);
        setfileRecibo([]);
        return isPDF || Upload.LIST_IGNORE;
      }
      setfileRecibo([file]);
      return false;
    },
    fileRecibo,
  };

  const propNotaCreditoPDF = {
    onRemove: (file) => {
      const index = fileNotaCredito.indexOf(file);
      const newFileList = fileNotaCredito.slice();
      newFileList.splice(index, 1);
      setfileNotaCredito(newFileList);
    },
    beforeUpload: (file) => {
      const isPDF = file.type === "application/pdf";
      if (!isPDF) {
        message.error(`${file.name} não é um ficheiro PDF`);
        setfileNotaCredito([]);
        return isPDF || Upload.LIST_IGNORE;
      }
      setfileNotaCredito([file]);
      return false;
    },
    fileNotaCredito,
  };

  const propComprovativoPDF = {
    onRemove: (file) => {
      const index = fileComprovativo.indexOf(file);
      const newFileList = fileComprovativo.slice();
      newFileList.splice(index, 1);
      setfileComprovativo(newFileList);
    },
    beforeUpload: (file) => {
      const isPDF = file.type === "application/pdf";
      if (!isPDF) {
        message.error(`${file.name} não é um ficheiro PDF`);
        setfileComprovativo([]);
        return isPDF || Upload.LIST_IGNORE;
      }

      setfileComprovativo([file]);
      return false;
    },
    fileComprovativo,
  };

  const getQuotas = (data) => {
    let groupQuotasByUser = [];
    data.users.map((user) => {
      let quotasByUser = data.quotas.filter(function (quota) {
        return quota.userId == user.id;
      });
      let quotasNaoPagas = quotasByUser.filter(function (quota) {
        return quota.status == "nao pago";
      });
      let observ = data.obs?.filter(function (observ) {
        return user.id == observ.user_id;
      });
      let newObs = "";
      if (observ.length) {
        newObs = observ[0].text;
      }
      if (quotasByUser.length) {
        quotasByUser?.sort((b, a) => a.ano - b.ano);
        let newname = "";
        if (!user.nome_completo) {
          newname = user.name;
          newname += user.apelido ? " " + user.apelido : "";
        } else {
          newname = user.nome_completo;
        }

        groupQuotasByUser.push({
          userId: user.id,
          numero_socio: user.numero_socio,
          nameToSearch: newname,
          name: (
            <>
              <div className="d-flex align-center">
                {newname}{" "}
                {newObs.length ? (
                  <Tooltip placement="top" title={newObs}>
                    <InfoCircleOutlined style={{ marginLeft: "10px" }} />
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            </>
          ),
          email: user.email,
          tipo_socio: user.tipo_socio,
          observ: newObs,
          quotas: quotasByUser,
          quotasNaoPagas: quotasNaoPagas.length,
        });
      }
    });
    setListaQuotas(groupQuotasByUser);
    setListaQuotasBkp(groupQuotasByUser);
    setDataToShow(() => getDataToShow(groupQuotasByUser));
  };

  useEffect(() => {
    getYears();
    fetchQuotas();
  }, []);

  const getYears = () => {
    let years = [];
    var currentYear = new Date().getFullYear();
    while (currentYear > 2020) {
      years.push({ text: currentYear, value: currentYear });
      currentYear--;
    }

    setYears(years);
  };

  const fetchQuotas = () => {
    fetch(`https://api.spap.pt/api/all-quotas`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar anticorpos");
        }
        return response.json();
      })
      .then((data) => {
        getQuotas(data);
      })
      .catch((error) => console.error(error));
  };

  /* Table */
  const mainColumns = [
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Tipo de sócio",
      dataIndex: "tipo_socio",
    },
    {
      title: "Nº de sócio",
      dataIndex: "numero_socio",
    },
  ];
  const expandedColumns = [
    {
      title: "Ano",
      dataIndex: "ano",
      filters: years,
      onFilter: (value, record) => {
        return record.ano == value;
      },
    },
    {
      title: "Tipo de Quota",
      dataIndex: "tipo",
      filters: [
        {
          text: "SPAP",
          value: "SPAP",
        },
        {
          text: "ESP",
          value: "ESP",
        },
        {
          text: "IAP",
          value: "IAP",
        },
      ],
      onFilter: (value, record) => {
        return record.tipo == value;
      },
    },
    {
      title: "Valor",
      dataIndex: "valor",
    },
    {
      title: "Data de pagamento",
      dataIndex: "payment_valid_date",
    },
    {
      title: "Estado",
      dataIndex: "estado",
      filters: [
        {
          text: "Pago",
          value: "Pago",
        },
        {
          text: "Não pago",
          value: "Não pago",
        },
      ],
      onFilter: (value, record) => {
        return record.estado == value;
      },
    },
    {
      title: "Ações",
      dataIndex: "actions",
    },
  ];

  const getDataToShow = (quotasAListar = []) => {
    if (quotasAListar?.length == 0) {
      quotasAListar = listaQuotas;
    }
    let data = [];
    for (let i = 0; i < quotasAListar.length; i++) {
      data.push({
        key: quotasAListar[i].userId,
        observ: quotasAListar[i].observ,
        name: <b>{quotasAListar[i].name}</b>,
        nameToSearch: quotasAListar[i].name,
        email: quotasAListar[i].email,
        tipo_socio: quotasAListar[i].tipo_socio,
        numero_socio: quotasAListar[i].numero_socio
          ? quotasAListar[i].numero_socio
          : "-",
        observacoes: quotasAListar[i].obs ? quotasAListar[i].obs : "-",
        quotas: quotasAListar[i].quotas,
        quotasNaoPagas: quotasAListar[i].quotasNaoPagas,
      });
    }

    return data;
  };

  const handleSearch = (event) => {
    let allQuotas = quotasBkp;
    let input = event.toString()?.toLowerCase();
    if (event.length == 0) {
      setListaQuotas(quotasBkp);
      return;
    }
    let novasQuotasAListar = allQuotas.filter((quota) => {
      if (
        quota.email?.toLowerCase().includes(input) ||
        quota.nameToSearch?.toLowerCase().includes(input) ||
        quota.observ.toString()?.toLowerCase().includes(input) ||
        quota.numero_socio?.toLowerCase().includes(input)
      ) {
        return quota;
      }
    });
    setDataToShow(() => getDataToShow(novasQuotasAListar));
  };

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 500,
    rtl: false,
  });

  const prop = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  const [value, setValue] = useState(1);
  const radioChange = (e) => {
    setValue(e.target.value);
  };

  const handleMenuClick = (e) => {
    message.info("Click on menu item.");
  };

  const items = [
    {
      label: (
        <RadioGroup>
          <Radio.Group onChange={radioChange} value={value}>
            <Space direction="vertical" className="space-align-block">
              <Radio value={1}>Aviso de cobrança</Radio>
              <Radio value={2}>Aviso de certificado</Radio>
              <Radio value={3}>Recibo de compras</Radio>
              <Radio value={4}>Ativar utilizador</Radio>
              <Radio value={5}>Desativar utilizador</Radio>
              <Button onClick={handleMenuClick}>
                <Space>
                  Enviar
                  <CheckOutlined />
                </Space>
              </Button>
            </Space>
          </Radio.Group>
        </RadioGroup>
      ),
    },
  ];

  const [tablePagination, setTablePagination] = useState(25);
  const selectHandleChange = (value) => {
    setTablePagination(value);
  };

  const t = 1;
  const data = [];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const steps = [
    {
      title: "Como fazer o upload dos pagamentos das quotas?",
      description:
        "É necessário seguir a ordem das colunas do CSV em formato UTF-8 do ficheiro fornecido em cima.",
      cover: (
        <div style={{ marginBottom: "10px" }}>
          <a
            href="https://api.spap.pt/uploads/ficheiro-template-quotas.csv"
            style={{
              padding: "12px 40px",
              textDecoration: "none",
              borderRadius: "6px",
              backgroundColor: "#494D7D",
              color: "#fff",
            }}
            download
          >
            Download do ficheiro template
          </a>
        </div>
      ),
      target: () => ref1.current,
    },
    {
      title: "Momento de fazer upload",
      description:
        "Assim que desejar fazer o upload do pagamento das quotas, basta clicar neste botão e será aberta uma modal com um campo para arrastar o ficheiro ou clicar para o introduzir manualmente.",
      target: () => ref2.current,
    },
    {
      title: "Fim",
      description: "Espero que tenha sido util!",
      target: () => ref2.current,
    },
  ];

  const tableOnChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const handleUpload = () => {
    readString(fileList[0], {
      worker: true,
      complete: (results) => {
        if (results.data.length == 0) {
          notification.error({
            description: "O Ficheiro não tem quotas para inserir.",
          });
        } else {
          var bodyFormData = new FormData();
          bodyFormData.append("array", JSON.stringify(results.data));
          setUploading(true);
          fetch("https://api.spap.pt/api/admin/upload-quotas", {
            method: "POST",
            body: bodyFormData,
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + session_storage.token,
            },
          }).then((response) => {
            response.json().then((data) => {
              if (data.message == "array_error") {
                notification.error({
                  description: "ERRO! O Ficheiro tem campos por preencher.",
                });
              } else {
                getQuotas(data);
                setIsModalOpen(false);
                notification.success({
                  description: "O ficheiro foi inserido com sucesso!",
                });
              }
              setFileList([]);
              setUploading(false);
            });
          });
        }
      },
    });
  };

  useEffect(() => {
    form.setFieldsValue(dataDrawer);
  }, [form, dataDrawer]);

  const onFormInputChange = (prop, values) => {
    setDataDrawer(values);
  };

  const openQuotaDrawer = (quota) => {
    console.log(quota);
    setfileRecibo([]);
    setfileNotaCredito([]);
    setfileComprovativo([]);

    let quotaDrawer = quota;
    quotaDrawer.valid_date =
      quotaDrawer.payment_valid_date != null
        ? quotaDrawer.payment_valid_date
        : "";
    quotaDrawer.phone = quotaDrawer.phone != "null" ? quotaDrawer.phone : "";
    quotaDrawer.payment_type =
      quotaDrawer.payment_type == "" ? null : quotaDrawer.payment_type;
    quotaDrawer.reference =
      quotaDrawer.reference != "null" ? quotaDrawer.reference : "";
    if (quota.payment_file_proof) {
      let thisProof = [
        {
          uid: "-1",
          name: quota.payment_file_proof,
          status: "done",
          url: "https://api.spap.pt/comprovantes/" + quota.payment_file_proof,
          thumbUrl:
            "https://api.spap.pt/comprovantes/" + quota.payment_file_proof,
        },
      ];
      setfileComprovativo(thisProof);
    }
    if (quota.payment_file_receipt) {
      let thisReceipt = [
        {
          uid: "-1",
          name: quota.payment_file_receipt,
          status: "done",
          url: "https://api.spap.pt/recibos/" + quota.payment_file_receipt,
          thumbUrl: "https://api.spap.pt/recibos/" + quota.payment_file_receipt,
        },
      ];
      setfileRecibo(thisReceipt);
    }
    if (quota.payment_file_credit) {
      let thisCredit = [
        {
          uid: "-1",
          name: quota.payment_file_credit,
          status: "done",
          url: "https://api.spap.pt/notaCredito/" + quota.payment_file_credit,
          thumbUrl:
            "https://api.spap.pt/notaCredito/" + quota.payment_file_credit,
        },
      ];
      setfileNotaCredito(thisCredit);
    }
    quotaDrawer.displayName = quotaDrawer?.name?.props?.children?.props
      .children[0]
      ? quotaDrawer?.name?.props?.children?.props.children[0]
      : quotaDrawer.name;
    setDataDrawer(quotaDrawer);
    form.setFieldsValue(quotaDrawer);
    setShowDrawer(true);
  };

  const handleSaveQuota = () => {
    const formData = new FormData();
    Object.keys(dataDrawer).forEach((key) => {
      formData.append(key, dataDrawer[key]);
    });
    if (fileComprovativo.length) {
      formData.append("proof", fileComprovativo[0]);
    }
    if (fileRecibo.length) {
      formData.append("receipt", fileRecibo[0]);
    }
    if (fileNotaCredito.length) {
      formData.append("credit", fileNotaCredito[0]);
    }
    fetch("https://api.spap.pt/api/admin/update-quota", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    }).then((response) => {
      response.json().then((data) => {
        if (data.message == "success") {
          notification.success({
            description: "A quota foi atualizada com sucesso!",
          });
        }
        data.newData.name = dataDrawer.displayName;
        // console.log(data.newData);
        openQuotaDrawer(data.newData);
        fetchQuotas();
        handleSearch(document.querySelector('input[name="searchable"]').value);
      });
    });
  };

  const handleQuotasData = (quotas, userName) => {
    let data = [];
    for (let i = 0; i < quotas.length; i++) {
      quotas[i].id = quotas[i].id;
      quotas[i].name = userName;
      data.push({
        key: quotas[i].id,
        ano: quotas[i].ano,
        tipo: quotas[i].tipo,
        valor: Number(quotas[i].valor) ? quotas[i].valor + " €" : "N/A",
        estado: quotas[i].status == "pago" ? "Pago" : "Não pago",
        payment_valid_date: quotas[i].payment_valid_date,
        actions: (
          <>
            <Button
              key={"editBtn" + quotas[i].id}
              onClick={() => openQuotaDrawer(quotas[i])}
            >
              Editar
            </Button>
          </>
        ),
      });
    }

    return data;
  };

  const handleSubmitObs = (userId, email) => {
    let text = document.querySelector(
      'textarea[name="observ-' + userId + '"]'
    ).value;
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("text", text);
    fetch("https://api.spap.pt/api/admin/update-observ", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    }).then((response) => {
      response.json().then((data) => {
        if (data.message == "success") {
          notification.success({
            description: "A observação foi atualizada com sucesso!",
          });
          fetchQuotas();
        }
      });
    });
  };

  return (
    <Wrapper>
      <div>
        <Sidebar />
      </div>
      <Content>
        <Header />
        <Card>
          <CardHeader>
            <Title>Quotas</Title>
            <Search>
              <input
                type="text"
                placeholder="Procurar..."
                name="searchable"
                onChange={(e) => handleSearch(e.target.value)}
              />
              <IconSearch className="icon icon-search" />
            </Search>
            <Space size={28}>
              <IconOptions
                className="icon icon-options"
                ref={ref1}
                onClick={() => setOpen(true)}
              />
              <IconUpload
                className="icon icon-upload"
                ref={ref2}
                onClick={() => setIsModalOpen(true)}
              />
            </Space>
          </CardHeader>
          <CardContent>
            <Table
              columns={mainColumns}
              onRow={(record, index) => ({
                style: {
                  background: record.quotasNaoPagas > 0 ? "#ffdddd" : "#ddffdd",
                },
              })}
              rowClassName={(record, index) =>
                record.quotasNaoPagas > 0 ? "table-danger" : "table-success"
              }
              expandable={{
                // rowExpandable: (record) => record.quotas.length > 0,
                expandedRowRender: (record) => (
                  <>
                    <TextArea
                      key={"observ-" + record.userId}
                      defaultValue={record.observ}
                      name={"observ-" + record.key}
                      style={{ marginBottom: "10px" }}
                      placeholder="Inserir observações"
                    />
                    <Button
                      key={"submitObs" + record.userId}
                      onClick={() => handleSubmitObs(record.key, record.email)}
                      style={{ float: "right", display: "block" }}
                    >
                      Guardar
                    </Button>
                    <br />
                    <Table
                      bodySortBg
                      columns={expandedColumns}
                      dataSource={handleQuotasData(
                        record.quotas,
                        record.nameToSearch
                      )}
                      onChange={tableOnChange}
                      pagination={false}
                      rowClassName={(record, index) =>
                        record.estado != "Pago" ? "table-warning" : ""
                      }
                    />
                  </>
                ),
              }}
              dataSource={dataToShow}
              onChange={tableOnChange}
              pagination={{ pageSize: tablePagination }}
            />
            <TableFooter>
              <Select
                defaultValue={tablePagination}
                style={{
                  width: 120,
                }}
                onChange={selectHandleChange}
                options={[
                  {
                    value: "10",
                    label: "10",
                  },
                  {
                    value: "25",
                    label: "25",
                  },
                  {
                    value: "50",
                    label: "50",
                  },
                ]}
              />
            </TableFooter>
          </CardContent>
        </Card>
        <CardQuotes />
        {/* <QuotasPrices /> */}
        <Footer />
        <Tour open={open} onClose={() => setOpen(false)} steps={steps} />
        <Modal
          key={"upload-quota"}
          open={isModalOpen}
          width={300}
          onCancel={hideModal}
          footer={[
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={fileList.length === 0}
              loading={uploading}
              style={{
                borderRadius: "12px",
                background: "#494D7D",
                width: "100%",
                height: "45px",
              }}
            >
              {uploading ? "Uploading" : "Start Upload"}
            </Button>,
          ]}
        >
          <h5
            style={{
              marginBottom: 0,
              fontSize: "18px",
              textAlign: "center",
              paddingTop: "20px",
              paddingBottom: "10px",
            }}
          >
            Faça upload do ficheiro
          </h5>

          <p
            style={{
              marginBottom: 0,
              fontSize: "14px",
              textAlign: "center",
              color: "#809FB8",
              paddingBottom: "2px",
            }}
          ></p>
          <Dragger {...prop} style={{ borderRadius: "12px" }}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Clique ou arraste para aqui o ficheiro relativo às quotas que
              pretende associar.
            </p>
          </Dragger>
        </Modal>
      </Content>
      <Drawer
        title={"Editar Quota"}
        width={800}
        onClose={() => setShowDrawer(false)}
        open={showDrawer}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={() => setShowDrawer(false)}>Cancelar</Button>
            <Button
              onClick={handleSaveQuota}
              style={{
                borderRadius: "6px",
                background: "#494D7D",
                color: "#fff",
              }}
            >
              Guardar
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="horizontal"
          initialValues={dataDrawer}
          onValuesChange={onFormInputChange}
        >
          <Row gutter={[12, 8]}>
            <Form.Item name="id" style={{ marginBottom: "12px" }}>
              <Input style={styles.input_class} hidden />
            </Form.Item>
            <Col span={24}>
              <label className="text">Nome do Sócio</label>
              <Form.Item name="displayName" style={{ marginBottom: "12px" }}>
                <Input style={styles.input_class} disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <label className="text">Ano</label>
              <Form.Item name={"ano"} style={{ marginBottom: "12px" }}>
                <Input style={styles.input_class} disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <label className="text">Tipo de Quota</label>
              <Form.Item name="tipo" style={{ marginBottom: "12px" }}>
                <Input style={styles.input_class} disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <label className="text">Estado</label>
              <Form.Item name="status" style={{ marginBottom: "12px" }}>
                <Select
                  style={styles.select_class}
                  placeholder="Estado"
                  options={[
                    { value: "nao pago", label: "Não pago" },
                    { value: "pago", label: "Pago" },
                  ]}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider>Dados do pagamento</Divider>
          <Row gutter={[12, 18]} align="bottom">
            <Col span={8}>
              <label className="text">Valor (€)</label>
              <Form.Item name="valor" style={{ marginBottom: "12px" }}>
                <Input style={styles.input_class} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <label className="text">Data de Pagamento</label>
              <br />
              <Form.Item name="valid_date" style={{ marginBottom: "12px" }}>
                <input type="date" style={styles.date_class} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <label className="text">Método de pagamento</label>
              <Form.Item name="payment_type" style={{ marginBottom: "12px" }}>
                <Select
                  placeholder="Método de pagamento"
                  style={styles.select_class}
                  options={[
                    { value: "MBWay", label: "MBWay" },
                    {
                      value: "Transferencia bancaria",
                      label: "Transferencia bancaria",
                    },
                    { value: "Multibanco", label: "Multibanco" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 18]} align="bottom">
            <Col span={8}>
              <label className="text">Nº Telemóvel</label>
              <br />
              <Form.Item name="phone" style={{ marginBottom: "12px" }}>
                <Input style={styles.input_class} placeholder="Nº Telemóvel" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <label className="text">Entidade</label>
              <br />
              <Form.Item name="entity" style={{ marginBottom: "12px" }}>
                <Input style={styles.input_class} placeholder="Entidade" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <label className="text">Referência</label>
              <Form.Item name="reference" style={{ marginBottom: "12px" }}>
                <Input style={styles.input_class} placeholder="Referência" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 18]} align="bottom">
            <Col span={24}>
              <label className="text">Descrição</label>
              <br />
              <Form.Item name="description" style={{ marginBottom: "12px" }}>
                <Input style={styles.input_class} placeholder="Descrição" />
              </Form.Item>
            </Col>
          </Row>
          <Divider>Ficheiros</Divider>
          <Row gutter={[12, 18]}>
            <Col span={8}>
              <label className="text">Recibo</label>
              <br />
              <Dragger
                key={"recibo" + dataDrawer.id}
                {...propReciboPDF}
                fileList={fileRecibo}
                style={{ borderRadius: "12px" }}
                accept="application/pdf"
              >
                <p className="ant-upload-text">Faça o upload do recibo</p>
                <p className="ant-upload-hint">
                  Apenas ficheiros com formato PDF são permitidos
                </p>
              </Dragger>
            </Col>
            <Col span={8}>
              <label className="text">Nota de crédito</label>
              <br />
              <Dragger
                key={"notaCredito" + dataDrawer.id}
                {...propNotaCreditoPDF}
                fileList={fileNotaCredito}
                style={{ borderRadius: "12px" }}
                accept="application/pdf"
              >
                <p className="ant-upload-text">
                  Faça o upload da nota de crédito
                </p>
                <p className="ant-upload-hint">
                  Apenas ficheiros com formato PDF são permitidos
                </p>
              </Dragger>
            </Col>
            <Col span={8}>
              <label className="text">Comprovativo</label>
              <br />
              <Dragger
                key={"comprovativo" + dataDrawer.id}
                {...propComprovativoPDF}
                fileList={fileComprovativo}
                style={{ borderRadius: "12px" }}
                accept="application/pdf"
              >
                <p className="ant-upload-text">Faça o upload do comprovativo</p>
                <p className="ant-upload-hint">
                  Apenas ficheiros com formato PDF são permitidos
                </p>
              </Dragger>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Wrapper>
  );
}
