import React, { useState } from 'react'
import styled from 'styled-components'
import FlexInRow from '../FlexInRow'

const Wrapper = styled.section`
  ${props => props.width && `
    width: ${props.width};
  `}  
`

const Title = styled.h3`
  text-align: left;
  color: ${props => props.theme.blue};
  opacity: 1;
  font-size: 1.250vw;
  font-weight: bold;
  letter-spacing: 0px;
  margin-bottom: 0;
  line-height: inherit;

  @media (max-width: 992px) {
    font-size: 28px;
    line-height: 34px;
    text-align: center;
  }

  @media (min-width: 1920px) {
    font-size: 24px;
    line-height: 28px;
  }
`

const Text = styled.p`
  text-align: left;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;

  @media (max-width: 992px) {
    font-size: 22px;
    line-height: 1.5;
  }

  @media (min-width: 1920px) {
    font-size: 18px;
    line-height: 24px;
  }
`

const ToggleClick = styled.span`
  cursor: pointer;

  b {
    background-color: ${props => props.theme.orange};
  }
`

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <Text>
      {isReadMore ? 
        <div dangerouslySetInnerHTML={{ __html: text.slice(0, 300) }}></div>
        : <div dangerouslySetInnerHTML={{ __html: text }}></div>}
      <ToggleClick onClick={toggleReadMore}>
        <b>{isReadMore && ' (...)'}</b>
      </ToggleClick>
    </Text>
  );
};

export default function PageText( props ) {

    return (
      <Wrapper className={props.className} width={props.width}>
        <FlexInRow gap="0" placeself="center">
          { props.title && <Title>{props.title}</Title> }
          { props.text && 
            props.readMore
            ? <ReadMore>{props.text}</ReadMore>
            : <Text><div dangerouslySetInnerHTML={{ __html: props.text }}></div></Text>
          }
        </FlexInRow>
      </Wrapper>
    )
}