import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Card from "../Card";
import Title from "../Title";
import { ReactSortable } from "react-sortablejs";
import {
  Button,
  Divider,
  Space,
  Switch,
  Upload,
  Input,
  Drawer,
  Col,
  Modal,
  Row,
  DatePicker,
  notification,
} from "antd";
import { ReactComponent as IconOk } from "../../../Assets/Images/Backoffice/Ok.svg";
import { ReactComponent as IconCalendar } from "../../../Assets/Images/Backoffice/Calendar.svg";
import { ReactComponent as IconClose } from "../../../Assets/Images/Backoffice/Close.svg";
import { ReactComponent as IconEdit } from "../../../Assets/Images/Backoffice/Edit.svg";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import {
  CloseCircleOutlined,
  SearchOutlined,
  MailOutlined,
} from "@ant-design/icons";
import axios, { all } from "axios";

const { RangePicker } = DatePicker;

const WrapperCardList = styled.section`
  // display: flex;
  width: 100% !important;
  flex-direction: row;

  p {
    color: #c2c4d4;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 20px;
    margin-bottom: 0% !important;
    text-align: left;
  }

  .text {
    color: #809fb8;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .text-small {
    color: #809fb8;
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .css-dev-only-do-not-override-j0nf2s .text {
    margin-bottom: 0 !important;
  }

  .icon-close {
    cursor: pointer;
  }

  .action-edit {
    cursor: pointer;
    color: #494d7d;
  }

  .flex-wrap {
    flex-wrap: wrap !important;
  }

  #input-search {
    .ant-input-suffix {
      background: none;
    }
  }
`;

const WrapperCardEvento = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  .grid {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
  }

  .icon {
    width: 25px;
  }

  .size-eye svg {
    width: 25px;
  }

  .ant-upload-btn {
  min-height: 190px;
}

  .ant-radio-button-wrapper {
    height: 45px;
    border: 1px solid rgb(128, 159, 184);
  }

  .ant-radio-button-wrapper span {
    line-height: 45px; 
    font-size: 14px;s
  }
  .text {
    margin-bottom: 0% !important;
  }

  h4 {
    text-align: center;
    font: normal normal bold 20px/20px Roboto;
    letter-spacing: 0px;
    color: #050505;
  }
  

  .text {
    color: #809FB8;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .center {
    text-align: center;
  }

  .ant-upload-wrapper .ant-upload-drag {
    max-height: -webkit-fill-available;
    height: 91%;
    display: flex;
    justify-content: center;
  }

  .ant-upload-wrapper .ant-upload-drag .ant-upload-btn  {
    height: -webkit-fill-available;
  }

  .div-sortable {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
  }

  .div-sortable .ant-col {
    padding: 5px;
  }

  .div-evento{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .cta {
    display: block !important;
    font-size: 15px;
    font-weight: bold;
    height: 45px !important;
    line-height: 18px;

    i { margin: 0 3px; }

    a { text-decoration: none; }
  }

  


  .cta.publish {
    margin-right: 0;

    svg path {
      fill: #FFF;
    }
  }
`;

var styles = {
  h6_label: {
    color: "#809FB8",
    fontFamily: "Roboto",
    fontSize: "15px",
    marginTop: "1%",
  },
  input_class_disabled: {
    marginBottom: "2%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    opacity: 0.6,
    pointerEvents: "none",
  },
  input_class: {
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    width: "100%",
  },
  select_class: {
    borderRadius: "6px",
    height: "45px",
    color: "#0C1151",
    width: "100%",
  },
  textarea_class: {
    borderRadius: "6px",
    border: "1px solid #809FB8",
    color: "#0C1151",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
};

const CardHeader = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: auto auto;

  .ps-end {
    place-self: start end;
  }
`;

export default function CardQuotes(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));

  const [dataFilter, setDataFilter] = useState([]);
  const [showEventDrawer, setShowEventDrawer] = useState(false);

  const dateFormat = "DD/MM/YYYY";
  const rangeFormat = "DD/MM/YYYY HH:mm";
  const [allItems, setAllItems] = useState([]);

  const [listItems, setListItems] = useState([]);

  const quillRef = useRef(null);

  /* Values novos */
  const [emailSend, setEmailSend] = useState(false);
  const [title, setTitle] = useState("");
  const [date, setDate] = useState([]);
  const [emailDate, setEmailDate] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailContent, setEmailContent] = useState("");

  /* Values Payments  */
  const [paymentAcceptMultibanco, setPaymentAcceptMultibanco] = useState(false);
  const [paymentAcceptMBWay, setPaymentAcceptMBWay] = useState(false);
  const [paymentAcceptDebitoDireto, setPaymentAcceptDebitoDireto] =
    useState(false);

  const [paymentValueSPAP, setPaymentValueSPAP] = useState("");
  const [paymentValueSPAPwESP, setPaymentValueSPAPwESP] = useState("");
  const [paymentValueNISPAP, setPaymentValueNISPAP] = useState("");
  const [paymentValueNISPAPwESP, setPaymentValueNISPAPwESP] = useState("");

  /* Values */
  const [isNISPAPUsers, setIsNISPAPUsers] = useState(false);
  const [isSPAPUsers, setIsSPAPUsers] = useState(false);
  const [isAllUsers, setIsAllUsers] = useState(true);

  // editar plano
  const [editId, setEditId] = useState("");
  const [editTitle, setEditTitle] = useState("");
  const [editDate, setEditDate] = useState([]);
  const [editPaymentAcceptMultibanco, setEditPaymentAcceptMultibanco] =
    useState(false);
  const [editPaymentAcceptMBWay, setEditPaymentAcceptMBWay] = useState(false);
  const [editPaymentAcceptDebitoDireto, setEditPaymentAcceptDebitoDireto] =
    useState(false);
  const [editPaymentValueSPAP, setEditPaymentValueSPAP] = useState("");
  const [editPaymentValueSPAPwESP, setEditPaymentValueSPAPwESP] = useState("");
  const [editPaymentValueNISPAP, setEditPaymentValueNISPAP] = useState("");
  const [editPaymentValueNISPAPwESP, setEditPaymentValueNISPAPwESP] =
    useState("");

  const [removeEventModalOpen, setRemoveEventModalOpen] = useState(false);
  const [idToRemove, setIdToRemove] = useState(false);
  const [titleToRemove, setTitleToRemove] = useState(false);

  const [disabledCard, setDisabledCard] = useState(false);

  const handleEventos = (itens) => {
    let eventosToShow = [];
    itens?.map((i) => {
      if (i != null) {
        if (i.data_inicio != null) {
          const [dateInicioValues, timeInicioValues] = i.data_inicio.split(" ");
          const [dayInicio, monthInicio, yearInicio] =
            dateInicioValues.split("/");

          i.date = dateInicioValues;
          i.time = timeInicioValues;
        }

        eventosToShow.push(i);
      }
    });

    if (eventosToShow?.length > 0) {
      eventosToShow.sort((a, b) => a.ordem - b.ordem);
    }
    setListItems(eventosToShow);
  };

  useEffect(() => {
    fetch(`https://api.spap.pt/api/all-plans`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar planos");
        }
        return response.json();
      })
      .then((data) => {
        let currentPlan = data.plans.filter((plan) => {
          var year = plan.date_start.substring(
            plan.date_start.lastIndexOf("/") + 1
          );
          if (year == new Date().getFullYear()) {
            return plan;
          }
        });
        if (Object.keys(currentPlan).length) {
          setDisabledCard(true);
        } else {
          setDisabledCard(false);
        }
        setAllItems(data.plans);
        handleEventos(data.plans);
      })
      .catch((error) => console.error(error));
  }, []);

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  const showModal = (e) => {
    setQuotaToUpdate(e);
    setIsModalOpen(true);
  };

  const clickToEventDrawer = (item) => {
    setEditId(item.id);
    setEditTitle(item.title);
    setEditDate([item.date_start, item.date_end]);
    setEditPaymentAcceptMultibanco(item.payment_accept_multibanco);
    setEditPaymentAcceptMBWay(item.payment_accept_mbway);
    setEditPaymentAcceptDebitoDireto(item.payment_accept_debito_direto);
    setEditPaymentValueSPAP(item.payment_value_spap);
    setEditPaymentValueSPAPwESP(item.payment_value_spap_esp);
    setEditPaymentValueNISPAP(item.payment_value_nispap);
    setEditPaymentValueNISPAPwESP(item.payment_value_nispap_esp);

    setShowEventDrawer(true);
  };

  useEffect(() => {
    if (showEventDrawer && quillRef.current) {
      const quillEditor = quillRef.current.getEditor();
      quillEditor.setText("");
      quillEditor.clipboard.dangerouslyPasteHTML(-0, descricaoEventEdit);
    }
  }, [showEventDrawer]);

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const onChangeEmailDate = (date, dateString) => {
    setEmailDate(dateString);
  };

  const onChangeDate = (dates, dateString) => {
    if (!dates) {
      setDate(null);
    } else {
      setDate(dateString);
    }
  };

  const onChangeDateEdit = (dates, dateString) => {
    if (!dates) {
      setEditDate(null);
    } else {
      setEditDate(dateString);
    }
  };

  const onChangeRangeFilter = (dates, dateString) => {
    if (!dates) {
      setDataFilter([]);

      actionFilterItens(valueSearch, []);
    } else {
      setDataFilter([dateString[0], dateString[1]]);

      actionFilterItens(valueSearch, [dateString[0], dateString[1]]);
    }
  };

  const [confModalVisible, setModalVisible] = useState(false);

  const confShowModal = () => {
    setModalVisible(true);
  };

  const confHandleOk = () => {
    setModalVisible(false);
    handleQuote();
  };

  const confHandleCancel = () => {
    setModalVisible(false);
  };

  const handleQuoteConfirm = (e) => {
    if (emailSend) {
      confShowModal();
    } else {
      handleQuote();
    }
  };

  const handleQuote = (e) => {
    if (
      title.length == 0 ||
      paymentValueSPAP.length == 0 ||
      paymentValueSPAPwESP.length == 0 ||
      (emailSend && (emailSubject.length == 0 || emailContent.length == 0)) ||
      (!paymentAcceptMultibanco &&
        !paymentAcceptMBWay &&
        !paymentAcceptDebitoDireto)
    ) {
      notification.error({
        description:
          "Para criar um novo plano/evento, preencha todos os campos.",
      });
      return;
    }

    const formData = new FormData();

    formData.append("title", title);
    formData.append("date_start", date[0]);
    formData.append("date_end", date[1]);

    formData.append("emailSend", emailSend ? 1 : 0);
    formData.append("emailDate", emailDate);
    formData.append("emailSubject", emailSubject);
    formData.append("emailContent", emailContent);

    formData.append("paymentAcceptMultibanco", paymentAcceptMultibanco ? 1 : 0);
    formData.append("paymentAcceptMBWay", paymentAcceptMBWay ? 1 : 0);
    formData.append(
      "paymentAcceptDebitoDireto",
      paymentAcceptDebitoDireto ? 1 : 0
    );

    formData.append("paymentValueSPAP", paymentValueSPAP);
    formData.append("paymentValueSPAPwESP", paymentValueSPAPwESP);
    formData.append("paymentValueNISPAP", paymentValueNISPAP);
    formData.append("paymentValueNISPAPwESP", paymentValueNISPAPwESP);

    formData.append("isNISPAPUsers", isNISPAPUsers ? 1 : 0);
    formData.append("isSPAPUsers", isSPAPUsers ? 1 : 0);
    formData.append("isAllUsers", isAllUsers ? 1 : 0);

    // You can use any AJAX library you like
    fetch("https://api.spap.pt/api/admin/store-plan", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            if (data.message == "already_exists") {
              notification.error({
                description: "Já existe uma quota com o mesmo nome!",
              });
            } else {
              session_storage.all_data.plans = data.quotes;
              localStorage.setItem(
                "session_data",
                JSON.stringify(session_storage)
              );

              if (emailSend) {
                const formSendEmail = new FormData();
                formSendEmail.append("date", emailDate);
                formSendEmail.append("subject", emailSubject);
                formSendEmail
                  .append("content", emailContent)

                  // fetch("https://api.spap.pt/api/send-payment-email", {
                  //     method: "POST",
                  //     body: formSendEmail,
                  //     headers: {
                  //       Accept: "application/json",
                  //       Authorization: "Bearer " + session_storage.token,
                  //     },
                  // })
                  // .then(response => response.json())
                  // .then(data => {
                  //     console.log('Sucesso:', data);
                  // })
                  .catch((error) => {
                    console.error("Erro:", error);
                  });
              }

              setTitle("");
              setDate([]);
              setEmailSend(false);
              setEmailDate(null);
              setEmailSubject("");
              setEmailContent("");
              setPaymentAcceptMultibanco(false);
              setPaymentAcceptMBWay(false);
              setPaymentAcceptDebitoDireto(false);
              setPaymentValueSPAP("");
              setPaymentValueSPAPwESP("");
              setPaymentValueNISPAP("");
              setPaymentValueNISPAPwESP("");
              setIsNISPAPUsers(false);
              setIsSPAPUsers(false);
              setIsAllUsers(true);

              handleEventos(data.quotes);
              notification.success({
                description: "O plano/evento foi criado com sucesso!",
              });
            }
          })
          .catch((err) => {});
      })
      .then(() => {})
      .catch(() => {})
      .finally(() => {});
  };

  const handleEditEvent = () => {
    if (false) {
      notification.error({
        description: "Para editar o plano, preencha os campos respetivos.",
      });
      return;
    }

    const formData = new FormData();

    formData.append("id", editId);
    formData.append("title", editTitle);
    formData.append("date_start", editDate[0]);
    formData.append("date_end", editDate[1]);

    formData.append(
      "paymentAcceptMultibanco",
      editPaymentAcceptMultibanco ? 1 : 0
    );
    formData.append("paymentAcceptMBWay", editPaymentAcceptMBWay ? 1 : 0);
    formData.append(
      "paymentAcceptDebitoDireto",
      editPaymentAcceptDebitoDireto ? 1 : 0
    );

    formData.append("paymentValueSPAP", editPaymentValueSPAP);
    formData.append("paymentValueSPAPwESP", editPaymentValueSPAPwESP);
    formData.append("paymentValueNISPAP", editPaymentValueNISPAP);
    formData.append("paymentValueNISPAPwESP", editPaymentValueNISPAPwESP);

    fetch("https://api.spap.pt/api/admin/update-plan", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            session_storage.all_data.plans = data.quotes;
            localStorage.setItem(
              "session_data",
              JSON.stringify(session_storage)
            );

            setAllItems(res.data.quotes);

            setCurrentItemsEvent(allItems.slice(itemOffsetEvent, endOffset));
          })
          .catch((err) => {});
      })
      .then(() => {
        notification.success({
          description: "O plano foi atualizado com sucesso!",
        });
        setShowEventDrawer(false);

        fetch(`https://api.spap.pt/api/all-plans`, {
          method: "GET",
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Erro ao buscar planos");
            }
            return response.json();
          })
          .then((data) => {
            let currentPlan = data.plans.filter((plan) => {
              var year = plan.date_start.substring(
                plan.date_start.lastIndexOf("/") + 1
              );
              if (year == new Date().getFullYear()) {
                return plan;
              }
            });
            if (Object.keys(currentPlan).length) {
              setDisabledCard(true);
            } else {
              setDisabledCard(false);
            }
            setAllItems(data.plans);
            handleEventos(data.plans);
          })
          .catch((error) => console.error(error));
      })
      .catch(() => {
        notification.error({
          description: "Já existe um plano com o mesmo nome neste clube!",
        });
      })
      .finally(() => {});
  };

  const onChangeIsDisable = (checked, id) => {
    let status = checked ? 1 : 0;

    axios
      .post(
        "https://api.spap.pt/api/admin/set-disable",
        { status, id },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        session_storage.all_data.plans = res.data.quotes;
        localStorage.setItem("session_data", JSON.stringify(session_storage));
        handleEventos(res.data.eventos);
      })
      .catch((error) => {});
  };

  const onChangePaymentAcceptMultibanco = (checked) => {
    checked
      ? setPaymentAcceptMultibanco(true)
      : setPaymentAcceptMultibanco(false);
  };

  const onChangePaymentAcceptMBWay = (checked) => {
    checked ? setPaymentAcceptMBWay(true) : setPaymentAcceptMBWay(false);
  };

  const onChangePaymentAccepDebitoDireto = (checked) => {
    checked
      ? setPaymentAcceptDebitoDireto(true)
      : setPaymentAcceptDebitoDireto(false);
  };

  const onChangePaymentAcceptMultibancoEdit = (checked) => {
    checked
      ? setEditPaymentAcceptMultibanco(true)
      : setEditPaymentAcceptMultibanco(false);
  };

  const onChangePaymentAcceptMBWayEdit = (checked) => {
    checked
      ? setEditPaymentAcceptMBWay(true)
      : setEditPaymentAcceptMBWay(false);
  };

  const onChangePaymentAccepDebitoDiretoEdit = (checked) => {
    checked
      ? setEditPaymentAcceptDebitoDireto(true)
      : setEditPaymentAcceptDebitoDireto(false);
  };

  const onChangeIsNISPAPUsers = (checked) => {
    if (checked) {
      setIsNISPAPUsers(true);
    } else if (isSPAPUsers) {
      setIsNISPAPUsers(false);
    } else {
      setIsNISPAPUsers(false);
      onChangeIsAllUsers(true);
    }
  };

  const onChangeIsSPAPUsers = (checked) => {
    if (checked) {
      setIsSPAPUsers(true);
    } else if (isNISPAPUsers) {
      setIsSPAPUsers(false);
    } else {
      setIsSPAPUsers(false);
      onChangeIsAllUsers(true);
    }
  };

  const onChangeIsAllUsers = (checked) => {
    checked ? setIsAllUsers(true) : setIsAllUsers(false);
  };

  const handleRemoveEvent = () => {
    axios
      .post(
        "https://api.spap.pt/api/admin/remove-plan",
        { idToRemove },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        setIdToRemove("");
        setRemoveEventModalOpen(false);
        handleEventos(res.data.quotes);
        notification.success({
          description: "O plano foi removido com sucesso!",
        });
      })
      .catch((error) => {
        notification.error({
          description: "Erro ao remover o plano! Tente novamente.",
        });
      });
  };

  const openRemoveEventModal = (id, titulo) => {
    setIdToRemove(id);
    setTitleToRemove(titulo);
    setRemoveEventModalOpen(true);
  };

  const handleOrdem = (array, tipo) => {
    /*
  
    let ordemItems = [];
    array.map(function(item,index){
      ordemItems.push({'ordem': index, 'id': item.id });
    });
  
    var bodyFormData = new FormData();
  
    bodyFormData.append('array', JSON.stringify(ordemItems));
  
  
    fetch('https://api.spap.pt/api/admin/set-ordem-plan', {
              method: 'POST',
              body: bodyFormData,
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + session_storage.token
              }
            })
            .then((response) => { 
              response.json().then((data) => {
                session_storage.all_data.plans = data.quotes;
                localStorage.setItem('session_data', JSON.stringify(session_storage));
                // handleEventos(data.quotes);
              });
            });

            */
  };

  /* CAMPO DE BUSCA */
  const [valueSearch, setValueSearch] = useState("");

  const handleSearchChange = (e) => {
    setValueSearch(e.target.value);

    actionFilterItens(e.target.value, dataEditEvent);
  };

  function actionFilterItens(text, date) {
    let filteredArray = allItems;
    if (date?.length !== 0) {
      const filteredEvents = filteredArray.filter((event) => {
        const eventStart = dayjs(event.data_inicio, "DD/MM/YYYY");
        const eventEnd = dayjs(event.data_fecho, "DD/MM/YYYY");
        const rangeStart = date[0] ? dayjs(date[0], "DD/MM/YYYY") : null;
        const rangeEnd = date[1] ? dayjs(date[1], "DD/MM/YYYY") : null;

        return (
          (!rangeStart || eventStart >= rangeStart) &&
          (!rangeEnd || eventEnd <= rangeEnd)
        );
      });

      filteredArray = filteredEvents;
    }

    const normalizeString = (str) =>
      str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    const posNormalize = normalizeString(text);
    const filtredItems = filteredArray.filter((item) =>
      normalizeString(item.titulo).includes(posNormalize)
    );

    handleEventos(filtredItems);
  }

  const handleSearchClear = () => {
    setValueSearch("");
    handleEventos(allItems);
  };

  return (
    <WrapperCardEvento>
      <Card
        style={{
          opacity: disabledCard ? 0.6 : 1,
          pointerEvents: disabledCard ? "none" : "auto",
        }}
      >
        <CardHeader>
          <Space direction="vertical" size={60}>
            <Title>Criar novo plano de quotas anual</Title>
          </Space>
          {/* <Space>
            <Row align={'middle'}>
              <MailOutlined style={{color: '#809FB8'}}/>
            </Row>
            <span style={{ fontSize: "14px" }}>Email</span>
            <Switch
              key={"is-event"}
              onChange={(e) => setEmailSend(e)}
              checked={emailSend}
            />
          </Space> */}
        </CardHeader>
        <Divider />
        <Row gutter={[12, 18]} align="bottom">
          <Col span={16}>
            <label className="text">Nome do Plano</label>
            <Input
              key={"titulo-event"}
              placeholder="Título"
              name="titulo"
              style={styles.input_class}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Col>
          <Col span={8}>
            <label className="text">Ano do plano</label>
            <Input
              style={styles.input_class}
              disabled
              value={
                disabledCard
                  ? new Date().getFullYear() + 1
                  : new Date().getFullYear()
              }
            />
          </Col>
        </Row>
        <Row gutter={[12, 18]} style={{ marginTop: "10px" }} align="bottom">
          <Col span={8}>
            <label className="text">Agendar envio</label>
            <Row>
              <RangePicker
                placement="bottomRight"
                style={{
                  width: "100%",
                  height: "45px",
                  border: "1px solid rgb(128, 159, 184)",
                }}
                format={dateFormat}
                disabled={true}
                placeholder={["Início", "Fim"]}
                // disabled={(!emailSend) && true}
                onChange={onChangeEmailDate}
              />
            </Row>
          </Col>
          <Col span={16}>
            <label className="text">Assunto do email</label>
            <Input
              key={"email-subject"}
              placeholder="Adicione o assunto do email aqui..."
              name="email-subject"
              style={styles.input_class}
              value={emailSubject}
              onChange={(e) => setEmailSubject(e.target.value)}
            />
          </Col>
        </Row>
        <Row gutter={[12, 12]} style={{ marginTop: "10px" }} align={"top"}>
          <Col span={24}>
            <label className="text" style={{ marginBottom: "0px" }}>
              Texto de corpo de email
            </label>
            <ReactQuill
              key={"email-content"}
              placeholder="Escreva aqui a sua mensagem"
              modules={modules}
              formats={formats}
              theme="snow"
              name="email-content"
              value={emailContent}
              onChange={(e) => setEmailContent(e)}
            />
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginTop: "10px" }} align="bottom">
          <Col span={12}>
            <label className="text">Tipo de pagamentos aceitos</label>
            <Row
              style={{
                backgroundColor: "#F4F9FB",
                borderRadius: "12px",
                border: "1px dashed #809FB8",
                height: "134px",
                marginBottom: "20px",
                padding: "25px",
              }}
            >
              <Col span={24}>
                <Space size={20}>
                  <Switch
                    onChange={(e) => onChangePaymentAcceptMultibanco(e)}
                    checked={paymentAcceptMultibanco}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#0C1151",
                    }}
                  >
                    Multibanco
                  </span>
                </Space>
              </Col>
              <Col span={24}>
                <Space size={20}>
                  <Switch
                    onChange={(e) => onChangePaymentAcceptMBWay(e)}
                    checked={paymentAcceptMBWay}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#0C1151",
                    }}
                  >
                    MbWay
                  </span>
                </Space>
              </Col>
              <Col span={24}>
                <Space size={20}>
                  <Switch
                    onChange={(e) => onChangePaymentAccepDebitoDireto(e)}
                    checked={paymentAcceptDebitoDireto}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#0C1151",
                    }}
                  >
                    Débito direto
                  </span>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[12, 18]}>
              <Col span={24}>
                <Row>
                  <Col span={24} style={{ marginBottom: "20px" }}>
                    <label className="text">
                      Valor em € (euros) Quota Titular SPAP
                    </label>
                    <Input
                      key={"payment-spap"}
                      placeholder=""
                      name="payment-spap"
                      style={styles.input_class}
                      value={paymentValueSPAP}
                      onChange={(e) => setPaymentValueSPAP(e.target.value)}
                    />
                  </Col>
                  <Col span={24} style={{ marginBottom: "20px" }}>
                    <label className="text">
                      Valor em € (euros) Quota SPAP + ESP
                    </label>
                    <Input
                      key={"payment-spap-esp"}
                      placeholder=""
                      name="payment-spap-esp"
                      style={styles.input_class}
                      value={paymentValueSPAPwESP}
                      onChange={(e) => setPaymentValueSPAPwESP(e.target.value)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider style={{ margin: "8px 0" }} />
        <Row>
          <Col span={16}>
            {/* <Space size={20}>
              <Space size={5}>
                <Switch 
                  onChange={(e) => onChangeIsNISPAPUsers(e)} 
                  checked={isNISPAPUsers} 
                />
                <span style={{ fontSize: "14px" }}>NISPAP</span>
              </Space>
              <Space>
                <Switch 
                  onChange={(e) => onChangeIsSPAPUsers(e)} 
                  checked={isSPAPUsers} 
                />
                <span style={{ fontSize: "14px" }}>SPAP</span>
              </Space>
              <Space>
                <Switch
                  checked={isAllUsers}
                  onChange={(e) => onChangeIsAllUsers(e)}
                />
                <span style={{ fontSize: "14px" }}>Todos os Sócios</span>
              </Space>
            </Space> */}
          </Col>
          <Col span={8}>
            <Row align={"end"}>
              <Button
                onClick={handleQuoteConfirm}
                type="primary"
                className="cta publish"
                align="end"
              >
                Salvar <IconOk className="icon icon-ok" />
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>

      {/* card list */}
      <WrapperCardList>
        <Row gutter={12} style={{ marginTop: "40px", marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={12} lg={10} xl={10} id={"input-search"}>
            <label style={{ marginBottom: "5px", color: "rgb(73, 77, 125)" }}>
              Filtrar por título:
            </label>
            <Input
              placeholder="Digite para buscar por planos"
              suffix={
                valueSearch ? (
                  <CloseCircleOutlined
                    onClick={handleSearchClear}
                    style={{ color: "#EE5555" }}
                  />
                ) : (
                  <SearchOutlined style={{ color: "#555" }} />
                )
              }
              value={valueSearch}
              onChange={handleSearchChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <label style={{ marginBottom: "5px", color: "rgb(73, 77, 125)" }}>
              Filtrar por data:
            </label>
            <RangePicker
              placeholder={["Dia de inicio", "Dia de fecho"]}
              key={"range-data-event"}
              format={"DD/MM/YYYY"}
              showTime={false}
              value={
                dataFilter.length > 0
                  ? [
                      dayjs(dataFilter[0], "DD/MM/YYYY"),
                      dayjs(dataFilter[1], "DD/MM/YYYY"),
                    ]
                  : undefined
              }
              onChange={onChangeRangeFilter}
              style={styles.input_class}
              placement="bottomRight"
            />
          </Col>
        </Row>

        {listItems?.length > 0 && (
          <>
            <ReactSortable
              list={listItems}
              setList={(e) => handleOrdem(e, "nao-definidos")}
              className="div-sortable"
            >
              {listItems.map((item, index) => {
                // let textPreview = item.descricao.replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>").slice(0, 120);
                let textPreview =
                  "Texto de corpo de email: Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.";
                if (textPreview?.length > 120) textPreview += " (...)";
                return (
                  <Col span={12}>
                    <Card
                      key={"eventos-" + item.id}
                      className="div-evento"
                      style={{ height: "100%" }}
                    >
                      <div>
                        <CardHeader>
                          <Title>{item.title}</Title>
                          <IconClose
                            className="icon icon-close ps-end"
                            onClick={() =>
                              openRemoveEventModal(item.id, item.titulo)
                            }
                          />
                        </CardHeader>
                        <div
                          style={{ fontSize: "12px", lineHeight: "1.4" }}
                          dangerouslySetInnerHTML={{ __html: textPreview }}
                        ></div>
                      </div>
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <Space
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignContent: "center",
                          }}
                        >
                          <Space size={5}>
                            <Space size={0}>
                              <IconCalendar className="icon icon-calendar" />
                              <span className="text-small">
                                {new Date(item.created_at)
                                  .toISOString()
                                  .split("T")[0]
                                  .split("-")
                                  .reverse()
                                  .join("/")}
                              </span>
                            </Space>
                            <Space>
                              {item.disable == 0 ? (
                                <>
                                  {" "}
                                  <Switch
                                    defaultChecked
                                    onChange={(e) =>
                                      onChangeIsDisable(e, item.id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <Switch
                                    onChange={(e) =>
                                      onChangeIsDisable(e, item.id)
                                    }
                                  />
                                </>
                              )}
                              <span className="text">
                                {item.disable == 1 ? "Desativo" : "Ativo"}
                              </span>
                            </Space>
                            <Space
                              size={0}
                              className="action-edit"
                              key={"evento-" + index}
                              onClick={() => clickToEventDrawer(item)}
                            >
                              <IconEdit className="icon icon-edit" />
                              <span>Editar</span>
                            </Space>
                          </Space>
                        </Space>
                      </Space>
                    </Card>
                  </Col>
                );
              })}
            </ReactSortable>
          </>
        )}

        <Drawer
          key={"editar-evento-editDrawer"}
          title="Editar Plano"
          width={700}
          onClose={() => setShowEventDrawer(false)}
          open={showEventDrawer}
          bodyStyle={{
            paddingBottom: 80,
          }}
          extra={
            <Space>
              <Button onClick={() => setShowEventDrawer(false)}>
                Cancelar
              </Button>
              <Button
                onClick={handleEditEvent}
                type="submit"
                style={{
                  borderRadius: "6px",
                  background: "#494D7D",
                  color: "#fff",
                }}
              >
                Guardar
              </Button>
            </Space>
          }
        >
          <form layout="vertical" id="update-event-form">
            <Row>
              <Col>
                <Divider />
                <Row gutter={[12, 18]} align="bottom">
                  <Col span={24}>
                    <label className="text">Nome do Plano</label>
                    <Input
                      key={"titulo-event"}
                      placeholder="Título"
                      name="titulo"
                      style={styles.input_class}
                      value={editTitle}
                      onChange={(e) => setEditTitle(e.target.value)}
                    />
                  </Col>
                </Row>
                <Divider />
                <Row gutter={60} style={{ marginTop: "10px" }} align="bottom">
                  <Col span={24}>
                    <Row gutter={[12, 18]}>
                      <Col span={24}>
                        <Row gutter={[8, 8]}>
                          <Col span={12} style={{ marginBottom: "20px" }}>
                            <label className="text">
                              Valor em € (euros) Quota SPAP
                            </label>
                            <Input
                              key={"payment-spap"}
                              placeholder=""
                              name="payment-spap"
                              style={styles.input_class}
                              value={editPaymentValueSPAP}
                              onChange={(e) =>
                                setEditPaymentValueSPAP(e.target.value)
                              }
                            />
                          </Col>
                          <Col span={12} style={{ marginBottom: "20px" }}>
                            <label className="text">
                              Valor em € (euros) Quota SPAP + ESP
                            </label>
                            <Input
                              key={"payment-spap-esp"}
                              placeholder=""
                              name="payment-spap-esp"
                              style={styles.input_class}
                              value={editPaymentValueSPAPwESP}
                              onChange={(e) =>
                                setEditPaymentValueSPAPwESP(e.target.value)
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <label className="text">Tipo de pagamentos aceitos</label>
                    <Row
                      style={{
                        backgroundColor: "#F4F9FB",
                        borderRadius: "12px",
                        border: "1px dashed #809FB8",
                        height: "134px",
                        marginBottom: "20px",
                        padding: "25px",
                      }}
                    >
                      <Col span={24}>
                        <Space size={20}>
                          <Switch
                            onChange={(e) =>
                              onChangePaymentAcceptMultibancoEdit(e)
                            }
                            checked={
                              editPaymentAcceptMultibanco != 0 ? true : false
                            }
                          />
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#0C1151",
                            }}
                          >
                            Multibanco
                          </span>
                        </Space>
                      </Col>
                      <Col span={24}>
                        <Space size={20}>
                          <Switch
                            onChange={(e) => onChangePaymentAcceptMBWayEdit(e)}
                            checked={editPaymentAcceptMBWay != 0 ? true : false}
                          />
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#0C1151",
                            }}
                          >
                            MbWay
                          </span>
                        </Space>
                      </Col>
                      <Col span={24}>
                        <Space size={20}>
                          <Switch
                            onChange={(e) =>
                              onChangePaymentAccepDebitoDiretoEdit(e)
                            }
                            checked={
                              editPaymentAcceptDebitoDireto != 0 ? true : false
                            }
                          />
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#0C1151",
                            }}
                          >
                            Débito direto
                          </span>
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        </Drawer>

        <Modal
          key={"remove-quote-" + idToRemove}
          title="Está prestes a eliminar um plano/evento"
          open={removeEventModalOpen}
          onOk={handleRemoveEvent}
          onCancel={() => setRemoveEventModalOpen(false)}
          okText="Confirmar"
          cancelText="Cancelar"
        >
          <p>
            Tem a certeza que pretende remover o plano/evento{" "}
            <b>'{titleToRemove}'</b>?
          </p>
        </Modal>

        <Modal
          title="Confirmação"
          visible={confModalVisible}
          onOk={confHandleOk}
          onCancel={confHandleCancel}
          okText="Confirmar"
          cancelText="Cancelar"
        >
          <p>Você tem certeza que deseja executar esta ação?</p>
        </Modal>
      </WrapperCardList>
    </WrapperCardEvento>
  );
}
