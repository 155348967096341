import styled from "styled-components";
import { Link } from "react-router-dom";

import Container from "../../../Components/Container";
import FlexInColumn from "../../../Components/FlexInColumn";
import FlexInRow from "../../../Components/FlexInRow";
import PageTitle from "../../../Components/PageTitle";
import PageText from "../../../Components/PageText";
import RecommendedLinks from "../../../Components/RecommendedLinks";

import { Helmet } from "react-helmet";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  img {
    width: 100%;
  }

  > .content {
    padding-bottom: 4.688vw;
  }

  .text {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .text a {
    color: #f88436;
    transition: 0.3s ease-out;

    :hover {
      text-decoration: none;
    }
  }

  .team {
    padding-bottom: 3.125vw;
  }

  .team__title {
    text-align: left;
    font-size: 1.25vw;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
  }

  .team .bar {
    height: 1px;
    width: 66.854vw;
    background-color: #d6cdf7;
    opacity: 1;
    margin: auto;
  }

  .team__items {
    display: grid;
    gap: 3.125vw;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .team__items b {
    text-align: left;
    font-size: 0.938vw;
    font-weight: bold;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .team__items p {
    text-align: left;
    font-size: 0.833vw;
    line-height: 1.563vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0;
  }

  .team .item__title {
    font-size: 1.042vw;
    font-weight: bold;
    line-height: 1.25vw;
    text-align: left;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
  }

  .team .item__image {
    overflow: hidden;
    width: 8.333vw;
    box-sizing: content-box;
  }

  .team .item__image img {
    object-fit: fill;
    height: 100%;
    width: 100%;
    border: 2px solid #0c1151;
    border-radius: 0.26vw;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .team__items {
      grid-template-columns: 1fr;
    }

    .team__title {
      font-size: 20px;
      line-height: 5.555vw;
    }

    .team .item__image {
      width: 80px;
    }

    .team__items p,
    .team__items b,
    .team__items a {
      font-size: 18px;
      line-height: 16px;
    }

    .text,
    .team .item__title {
      font-size: 20px;
      line-height: 1.5;
    }
  }

  @media (min-width: 1920px) {
    .text {
      font-size: 18px;
      line-height: 24px;
    }

    .team {
      gap: 30px;
      padding-bottom: 100px;
    }

    .team__title {
      font-size: 24px;
      line-height: 28px;
    }

    .team .item__title {
      font-size: 20px;
      line-height: 24px;
    }

    .team .item__image {
      width: 160px;
    }

    .team__items b {
      font-size: 18px;
      line-height: 24px;
    }

    .team__items p {
      font-size: 16px;
      line-height: 30px;
    }

    .team .bar {
      width: 1244px;
    }
  }
`;

const SectionMembers = styled.section`
  margin: 0 auto;
  width: 41.458vw;

  @media (max-width: 992px) {
    width: 100%;
  }

  @media (min-width: 1920px) {
    width: 796px;
  }
`;

const SectionMembersContent = styled.div`
  border: 0.156vw solid #0c1151;
  border-radius: 1.042vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "image title"
    "image text"
    "image cta";
  opacity: 1;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "title"
      "image"
      "text"
      "cta";
    gap: 10px;
  }
`;

const SectionMembersImage = styled.div`
  border-radius: 1.042vw 0px 0px 1.042vw;
  grid-area: image;
  overflow: hidden;
`;

const SectionMembersTitle = styled.h3`
  grid-area: title;
  text-align: left;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  margin: 1.042vw 1.042vw 0;
  place-self: flex-end flex-start;

  @media (max-width: 992px) {
    font-size: 24px;
    line-height: 32px;
    place-self: center;
  }

  @media (min-width: 1920px) {
    font-size: 24px;
    line-height: 28px;
    margin: 20px 20px 0;
  }
`;

const SectionMembersText = styled.p`
  grid-area: text;
  text-align: left;
  font-size: 0.938vw;
  line-height: 1.25vw;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 1.042vw;
  place-self: center flex-start;

  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 22px;
    place-self: center;
  }

  @media (min-width: 1920px) {
    font-size: 18px;
    line-height: 24px;
    margin: 20px;
  }
`;

const SectionMembersCta = styled.div`
  grid-area: cta;
  place-self: flex-start;
  align-items: center;
  justify-content: center;
  margin: 1.042vw;

  a {
    text-align: center;
    font-size: 0.938vw;
    font-weight: bold;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    background-color: #090d3d;
    border-radius: 0.521vw;
    opacity: 1;
    transition: 0.3s ease;
    text-decoration: none;
    padding: 0.677vw 1.563vw;
  }

  a:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  @media (max-width: 992px) {
    place-self: center;
    margin-bottom: 20px;

    a {
      border-radius: 8px;
      font-size: 16px;
      line-height: 22px;
      padding: 10px;
      margin: auto;
    }
  }

  @media (min-width: 1920px) {
    margin: 20px;

    a {
      border-radius: 10px;
      font-size: 18px;
      line-height: 24px;
      padding: 15px 30px;
    }
  }
`;

export default function OrgaosSociais(props) {
  return (
    <Wrapper>
      <Helmet>
        <title>
          Orgãos Sociais | Sociedade Portuguesa de Anatomia Patológica - SPAP
        </title>
        <meta
          name="description"
          content="Mandato 2021-2024. A nova direção da sociedade foi eleita em novembro de 2021, com elementos de todo o país, interessados em reavivar a sociedade, e fazer a sociedade trabalhar pelos patologistas."
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container className="content">
        <PageTitle
          title="ÓRGÃOS SOCIAIS"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "SPAP",
              link: "/spap",
            },
            {
              text: "Órgãos sociais",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/img-circle-orgaos-sociais@2x.png")}
          imageMobile={require("../../../Assets/Images/img-circle-orgaos-sociais.png")}
          imageAlt="Orgãos Sociais"
        />

        <FlexInRow gap="48px">
          <FlexInRow gap="0">
            <PageText
              title="Mandato 2021-2024"
              text="A nova direção da sociedade foi eleita em novembro de 2021, com elementos de todo o país, interessados em reavivar a sociedade, e fazer a sociedade trabalhar pelos patologistas. "
            />
            <p className="text">
              Neste mandato pretendemos atualizar os estatutos, renovar o site,
              e fazer com que a comunidade torne-se mais próxima, fomentando
              cursos, encontros e a comunicação entre os sócios.{" "}
            </p>
            <p className="text">
              Leia a <span className="orange">mensagem da direção</span>,
              lançada em dezembro de 2021 <a href="/agenda/evento/11">aqui</a>.
            </p>
          </FlexInRow>

          <FlexInRow gap="3.125vw" className="team">
            <FlexInColumn gap="1.042vw">
              <h2 className="team__title">Direção</h2>
              <div className="bar"></div>
            </FlexInColumn>

            <div className="team__items">
              <FlexInRow gap="1.042vw">
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Presidente</h3>
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/foto-m-augusta-cipriano@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/foto-m-augusta-cipriano.png")}
                        alt="M. Augusta Cipriano"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw">
                      <b>M. Augusta Cipriano</b>
                      <p>CHUC-Coimbra</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
              </FlexInRow>
            </div>

            <div className="team__items">
              <FlexInRow gap="1.042vw">
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Vice - Presidentes</h3>
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/foto-catarina-eloy@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/foto-catarina-eloy.png")}
                        alt="Catarina Eloy"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw">
                      <b>Catarina Eloy</b>
                      <p>IPATIMUP-Porto</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
                <FlexInRow gap="0.521vw" className="item">
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/foto-joaninha-costa-rosa@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/foto-joaninha-costa-rosa.png")}
                        alt="Joaninha Costa Rosa"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw">
                      <b>Joaninha Costa Rosa</b>
                      <p>IPO-Lisboa</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
              </FlexInRow>
              <FlexInRow gap="1.042vw">
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Secretário Geral</h3>
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/foto-raquel-ilgenfritz@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/foto-raquel-ilgenfritz.png")}
                        alt="Raquel Ilgenfritz"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw">
                      <b>Raquel Ilgenfritz</b>
                      <p>CUF Descobertas-Lisboa</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Vogal Tesoureiro</h3>
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/foto-carla-bartosch@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/foto-carla-bartosch.png")}
                        alt="Carla Bartosch"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw">
                      <b>Carla Bartosch</b>
                      <p>IPO-Porto</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
              </FlexInRow>
              <FlexInRow gap="1.042vw">
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Vogais</h3>
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/foto-rui-oliveira@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/foto-rui-oliveira.png")}
                        alt="Rui Caetano Oliveira"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw">
                      <b>Rui Caetano Oliveira</b>
                      <p>
                        Germano Sousa -<br />
                        CEDAP-Coimbra
                      </p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
                <FlexInRow gap="0.521vw" className="item">
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/foto-daniel-pinto@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/foto-daniel-pinto@2x.png")}
                        alt="Daniel Pinto"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw">
                      <b>Daniel Pinto</b>
                      <p>HGO-Almada</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
              </FlexInRow>
            </div>

            <FlexInColumn gap="1.042vw">
              <h2 className="team__title">Assembleia-Geral</h2>
              <div className="bar"></div>
            </FlexInColumn>

            <div className="team__items">
              <FlexInRow gap="1.042vw">
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Presidente</h3>
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/foto-Paula-Borralho@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/foto-Paula-Borralho.png")}
                        alt="Paula Borralho"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw">
                      <b>Paula Borralho</b>
                      <p>CUF Descobertas-Lisboa</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
              </FlexInRow>
              <FlexInRow gap="1.042vw">
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Vice-Presidente</h3>
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/foto-Antonia-Furtado@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/foto-Antonia-Furtado.png")}
                        alt="Antónia Furtado"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw">
                      <b>Antónia Furtado</b>
                      <p>IMP-Porto</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
              </FlexInRow>
              <FlexInRow gap="1.042vw">
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Secretário</h3>
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/foto-Rita-Luis@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/foto-Rita-Luis.png")}
                        alt="Rita Luís"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw">
                      <b>Rita Luís</b>
                      <p>CHULN-Lisboa</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
              </FlexInRow>
            </div>

            <FlexInColumn gap="1.042vw">
              <h2 className="team__title">Conselho Fiscal</h2>
              <div className="bar"></div>
            </FlexInColumn>

            <div className="team__items">
              <FlexInRow gap="1.042vw">
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Presidente</h3>
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/foto-raquel-portugal@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/foto-raquel-portugal.png")}
                        alt="Raquel Portugal"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw">
                      <b>Raquel Portugal</b>
                      <p>CHUSJ-Porto</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
              </FlexInRow>
              <FlexInRow gap="1.042vw">
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Secretário</h3>
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/foto-antonio-alvez@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/foto-antonio-alvez.png")}
                        alt="António Alves"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw">
                      <b>António Alves</b>
                      <p>CUF Descobertas-Lisboa</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
              </FlexInRow>
              <FlexInRow gap="1.042vw">
                <FlexInRow gap="0.521vw" className="item">
                  <h3 className="item__title">Relator</h3>
                  <FlexInColumn gap="1.042vw">
                    <picture className="item__image">
                      <source
                        srcSet={require("../../../Assets/Images/foto-andre-coelho@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/foto-andre-coelho.png")}
                        alt="André Coelho"
                      />
                    </picture>
                    <FlexInRow gap="0.521vw">
                      <b>André Coelho</b>
                      <p>CHUP-Porto</p>
                    </FlexInRow>
                  </FlexInColumn>
                </FlexInRow>
              </FlexInRow>
            </div>
          </FlexInRow>

          <SectionMembers>
            <SectionMembersContent>
              <SectionMembersImage>
                <picture>
                  <source
                    srcSet={require("../../../Assets/Images/img-contacte-nos-footer@2x.png")}
                    media="(min-width: 990px)"
                  />
                  <img
                    src={require("../../../Assets/Images/img-contacte-nos-footer.png")}
                    alt="Seja um Membro"
                  />
                </picture>
              </SectionMembersImage>
              <SectionMembersTitle>Contacte-nos</SectionMembersTitle>
              <SectionMembersText>
                Para algum assunto relacionado com a SPAP, adesões de sócios e
                outros temas, envie-nos uma mensagem.
              </SectionMembersText>
              <SectionMembersCta>
                <Link to="/spap/contactos">Saber mais »</Link>
              </SectionMembersCta>
            </SectionMembersContent>
          </SectionMembers>
        </FlexInRow>
      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/historia",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
